import React from 'react';
const Field = ({ errors, label, children, labelBr, noncol6 }) => (
    <div className={`form-group mr-3 ${noncol6 ? 'col-sm-10' : 'col-sm-5'}`}>
        {label && <label className="label">{label}</label>}
        {labelBr && <br />}
        {React.cloneElement(children, {
            className: `form-control ${errors ? 'is-danger' : ''}`
        })}
        {errors ? <div className="alert alert-danger">{errors[0]}</div> : null}
    </div>
);

export default Field;
