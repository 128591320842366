import React from 'react';
import { Link } from 'react-router-dom';
import AgentCard from '../components/AgentCard'
import axios from 'axios';
import { Header } from '../components/Header';
var base_ = "https://homehunt-admin.herokuapp.com/";
class Agents extends React.Component {


    state = {

        dataz: [],

    }
    componentDidMount() {

        //  alert(myParam);
        axios.get(base_ + 'api/user/properties')
            .then((response) => {
                // handle success
                console.log(response.data);

                this.setState({

                    dataz: response.data



                })

                //console.log(this.state.coords.coordinates);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }





    render() {
        return (
            <div>
                <div >
                    <br /><br /><br /><br />
                    <section className="pager-sec bfr widget_edit_enabled" style={{ marginTop: '15px' }}>
                        <div className="container">
                            <div className="pager-sec-details">
                                <h3>Agents</h3>
                                <ul className="breadcrumb"><li><a href='../../en.html'>Homepage</a><span className="delimiter"></span></li><li><span>Agents</span></li></ul>        </div>
                        </div>
                    </section>        <section className="listing-main-sec section-padding2">
                        <div className="container">
                            <div className="listing-main-sec-details">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="clearfix section-agents agents-list" id="agent-search">
                                            <div className="agents-details">
                                                <div className="row">
                                                    {this.state.dataz.map(function (d, index) {

                                                        return <AgentCard key={d._id} obj={d} />

                                                    })}



                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="pagination pagination-centered">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="sidebar layout2">
                                        <div className="widget widget-search">
                                            <form action="https://geniuscript.com/selio-script/index.php/en/169/agents#agent-search" method="get">
                                                <input type="text" name="search-agent" value="" placeholder="Search" />
                                                <button type="submit"><i className="la la-search"></i></button>
                                            </form>
                                        </div>


                                        <div className="widget widget-catgs widget_edit_enabled">
                                            <h3 className="widget-title">Categories</h3>
                                            <ul>
                                                <li>
                                                    <a href='../145/indexec2a.html?search={%22v_search_option_79%22:%22Apartment%20-%20%22}' title="Apartment"><i className="la la-angle-right"></i><span>Apartment</span></a>
                                                    <span>0</span>
                                                </li>
                                                <li>
                                                    <a href='../145/indexb15d.html?search={%22v_search_option_79%22:%22House%20-%20%22}' title="House"><i className="la la-angle-right"></i><span>House</span></a>
                                                    <span>1</span>
                                                </li>
                                                <li>
                                                    <a href='../145/index465b.html?search={%22v_search_option_79%22:%22Commercial%20-%20%22}' title="Commercial"><i className="la la-angle-right"></i><span>Commercial</span></a>
                                                    <span>1</span>
                                                </li>
                                                <li>
                                                    <a href='../145/index0a6e.html?search={%22v_search_option_79%22:%22Restaurant%20-%20%22}' title="Restaurant"><i className="la la-angle-right"></i><span>Restaurant</span></a>
                                                    <span>0</span>
                                                </li>
                                                <li>
                                                    <a href='../145/indexe562.html?search={%22v_search_option_79%22:%22Land%20-%20%22}' title="Land"><i className="la la-angle-right"></i><span>Land</span></a>
                                                    <span>0</span>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="widget widget-posts widget_edit_enabled">
                                            <h3 className="widget-title">Popular Listings</h3>
                                            <ul>
                                                <li>
                                                    <div className="wd-posts">
                                                        <div className="ps-img">
                                                            <a href="../../property/30/en/bjelovar_estate_1.html" title="Bjelovar estate 1">
                                                                <img src="assets/112x89431262903_9d77804a5b_o%20(1).jpg" alt="Bjelovar estate 1" />
                                                            </a>
                                                        </div>
                                                        <div className="ps-info">
                                                            <h3><a href="../../property/30/en/bjelovar_estate_1.html" title="Bjelovar estate 1">Bjelovar estate 1</a></h3>
                                                            <strong>
                                                                $90,000.00                    </strong>
                                                            <span><i className="la la-map-marker"></i>Cestica</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>

                <div className="popup" id="sign-popup">
                    <h3>Sign In to your Account</h3>
                    <div className="popup-form form-wr">
                        <form id="popup_form_login">
                            <div className="alert alert-success m0" role="alert">
                                <b>Demo login details for Admin:</b><br />
                                Username: admin<br />
                                Password:  admin<br /><br />
                                <b> Demo login details for User:</b><br />
                                Username:  user<br />
                                Password:  user                </div>
                            <div className="alerts-box"></div>
                            <div className="form-field">
                                <input type="text" name="username" value="" className="form-control" id="inputUsername" placeholder="Username" />            </div>
                            <div className="form-field">
                                <input type="password" name="password" value="" className="form-control" id="inputPassword" placeholder="Password" />            </div>
                            <div className="form-cp">
                                <div className="form-field">
                                    <div className="input-field">
                                        <input type="checkbox" name="remember" id="remember" value="true" />
                                        <label for="remember">
                                            <span></span>
                                            <small>Remember me</small>
                                        </label>
                                    </div>
                                </div>
                                <a href="../../admin/user/forgetpassword.html" className="forgot-password" title="Forgot Password?">Forgot Password?</a>
                            </div>
                            <button type="submit" className="btn2">Sign In</button>
                        </form>
                        <a href="../../frontend/login/en.html#sw_register" className="link-bottom">Create new account</a>
                        <a href="#" className="fb-btn"><i className="fa fa-facebook" aria-hidden="true"></i>Sign in with facebook</a>
                    </div>
                </div>

                <div className="se-pre-con"></div>

            </div>
        )
    }
}

export default Agents;