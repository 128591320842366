import React from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import { apiService } from '../../_services/api.service';
import PerfectPlaces from '../../components/PerfectPlaces';
import { APILoader } from '../../components/APILoader';
import { Header } from '../../components/Header';



export default class States extends React.Component {
    state = {
        states: null,
    }


    componentDidMount() {
        this.getStates();
    }
    getStates = () => {
        apiService.getAll("states").then(states => {
            this.setState({ states })
        })
    }

    render() {
        let { states } = this.state;
        return (
            <div>
                <section className="popular-cities hp_s1 section-padding widget_edit_enabled">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8">
                                <div className="section-heading">
                                    <span>States</span>
                                    <h3>Find Perfect Place</h3>

                                    <div className="row pt-2">
                                        {
                                            states ?
                                                states.map((obj, key) => <PerfectPlaces key={key} obj={obj} />)
                                                :
                                                <div className="col-sm-12 mt-5">
                                                    <APILoader
                                                        type="Puff"
                                                        color="#00BFFF"
                                                        height={50}
                                                        width={50}
                                                    // timeout={3000} //3 secs

                                                    />
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        </div>
                    </div>
                </section>

            </div>
        );

    }
}