import config from "../_config";
import io from 'socket.io-client';

let socket = io(config.baseUrl, config.socketHeader);


export const favouritesService = {
    save: (property) => {
        let list = favouritesService.getAll();
        let favouriteProperty = list.find(m => m._id === property._id);
        if (favouriteProperty) {
            return;
        }
        list.push(property);
        localStorage.setItem('favourites', JSON.stringify(list));
        socket.emit("request", {
            action: "changes-favourite", data: {
                _id: property._id,
                ip: localStorage.getItem("ip")
            }
        });
        // debugger;
        // if (EventBus) {
        //     const loginEvent = new CustomEvent("changes-favourite", { detail: { _id: property._id } });
        //     EventBus.dispatchEvent(loginEvent);
        // }
    },
    get: (_id) => {
        let list = favouritesService.getAll();
        return list.find(m => m._id == _id);
    },
    getAll: () => {
        let lsf = localStorage.getItem('favourites');
        if (!lsf) {
            return [];
        }
        return JSON.parse(lsf);
    },
    remove: (_id) => {
        let list = favouritesService.getAll();
        let index = list.findIndex(m => m._id === _id);
        if (index == -1) {
            return;
        } else {
            list.splice(index, 1);
        }
        localStorage.setItem('favourites', JSON.stringify(list));

        socket.emit("request", {
            action: "changes-favourite", data: {
                _id,
                ip: localStorage.getItem("ip")
            }
        });
        // if (EventBus) {
        //     const loginEvent = new CustomEvent("changes-favourite", { detail: { _id } });
        //     EventBus.dispatchEvent(loginEvent);
        // }
    }
}