
import React from 'react';
import { Link } from 'react-router-dom';
import getImageURL from '../_helpers/imageHelper';
class ThumbImgs extends React.Component {

    render() {
        let {path}=this.props;
        return (
            <div className="col-lg-4 col-md-4 col-sm-4 col-4 thumb-img">
                <div className="property-img">
                    <img src={getImageURL(path)} alt="" title="" />
                </div>
            </div>
        )
    }
}

export default ThumbImgs;