import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { authenticationActions } from '../_actions/authentication.actions';
import { connect } from 'react-redux';

import { apiService } from '../_services/api.service';
import io from 'socket.io-client';
import config from '../_config';
import Axios from 'axios';

class Header extends React.Component {
    state = {
        currentIP: null
    }
    componentDidMount() {
        let { EventBus } = this.props;
        this.getUser();


        let socket = io(config.baseUrl, config.socketHeader);
        Axios.get('https://api.ipify.org/?format=json').then(({ data }) => {
            localStorage.setItem("ip", data.ip);
            this.setState({ currentIP: data.ip });

            socket.on("auth-changes", (m) => {
                if (m.ip == data.ip) {
                    this.getUser();
                }
            });
        });




    }
    getUser = () => {
        this.props.dispatch(authenticationActions.getCurrentUser())
    }

    logout = () => {
        let { history } = this.props;
        this.props.dispatch(authenticationActions.logout(history));
        this.getUser();
    }

    render() {
        let { user, history, infosettings } = this.props;
        return (
            <div className="row">
                <div className="wpart">
                    <div className="top-header widget_edit_enabled">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-xl-7 col-md-7 col-sm-12">

                                    {user ?
                                        <div className="header-address">

                                            <Link to="/favorites">
                                                <i className="fa fa-star"></i>
                                                <span>Favorites</span>
                                            </Link>
                                            <Link to="/user/properties"><i className="fa fa-list"></i> <span>My properties</span></Link>
                                            <Link to="/user/custom-notifications"><i className="fa fa-envelope"></i> <span>Notifications</span></Link>
                                            <Link to="/user/orders"><i className="fa fa-cart-arrow-down"></i> <span>Orders</span></Link>
                                            <Link to="/profile" className="login_popup_enabled">
                                                <i className="la la-user"></i>
                                                <span>{user.email}</span>
                                            </Link>
                                        </div>
                                        :
                                        <div className="header-address">
                                            <Link to="/favorites">
                                                <i className="fa fa-star"></i>
                                                <span>Favorites</span>
                                            </Link>
                                            <a href={`tel:${infosettings && infosettings.phoneNo}`}>
                                                <i className="la la-phone-square"></i>
                                                <span>{infosettings && infosettings.phoneNo}1</span>
                                            </a>
                                            <a href={`mailto:${infosettings && infosettings.email}`}>
                                                <i className="la la-envelope-o"></i>
                                                <span>{infosettings && infosettings.email}</span>
                                            </a>


                                            <Link to="/login" className="login_popup_enabled">
                                                <i className="la la-user"></i>
                                                <span>Login</span>
                                            </Link>
                                        </div>
                                    }
                                </div>


                                <div className="col-xl-3 col-lg-2 col-md-2 col-sm-6 col-6">
                                    <div className="header-social">
                                        <a href={infosettings && infosettings.facebookLink} target="_blank"><i className="fa fa-facebook"></i></a>
                                        <a href={infosettings && infosettings.twitterLink} target="_blank"><i className="fa fa-twitter"></i></a>
                                        <a href={infosettings && infosettings.linkedinLink} target="_blank"><i className="fa fa-linkedin"></i></a>
                                        <a href={infosettings && infosettings.instagramLink} target="_blank"><i className="fa fa-instagram"></i></a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="header widget_edit_enabled">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <nav className="navbar navbar-expand-lg navbar-light">
                                        <Link to='/' className="nav-link">
                                            <img src="assets/img/logo.png" alt="Home Hunt" style={{ height: 80 }} />
                                        </Link>
                                        <button className="menu-button" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                                            <span className="icon-spar"></span>
                                            <span className="icon-spar"></span>
                                            <span className="icon-spar"></span>
                                        </button>
                                        <div className="navbar-collapse" id="navbarSupportedContent">
                                            <ul className="navbar-nav mr-auto ml-5" id="main-menu">
                                                <li className="nav-item ">
                                                    <Link to='/' className="nav-link">Home</Link>

                                                </li>
                                                <li className="nav-item ">
                                                    <Link to='/featured' className="nav-link">Featured</Link></li>
                                                <li className="nav-item ">
                                                    <Link to='/about' className="nav-link">About</Link>
                                                </li>
                                                <li className="nav-item "><Link to='/contact' className="nav-link">Contact</Link></li>
                                            </ul>
                                            <div className="d-inline my-2 my-lg-0">
                                                <ul className="navbar-nav">
                                                    <li className="nav-item signin-btn">

                                                        <span className="nav-link">
                                                            <i className="la la-sign-in"></i>
                                                            {user ?
                                                                <span>
                                                                    <button className="btn btn-link" onClick={this.logout}>
                                                                        <b className="signin-op">Logout</b>
                                                                    </button>
                                                                </span>
                                                                :
                                                                <span>
                                                                    <Link to='/login' ><b className="signin-op">Sign in</b></Link>
                                                                    <span> or </span>
                                                                    <Link to="/register"><b className="reg-op">Register</b></Link>
                                                                </span>
                                                            }


                                                        </span>
                                                    </li>

                                                </ul>

                                            </div>
                                            <a href="#" title="" className="close-menu">
                                                <i className="la la-close"></i>
                                            </a>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { loggingIn, user } = state.authentication;
    const { alert } = state;
    return {
        loggingIn,
        alert,
        user
    };
}

const connected = connect(mapStateToProps)(withRouter(Header));
export { connected as Header };