import React from 'react';
import { Link } from 'react-router-dom';
import getImageURL from '../_helpers/imageHelper';
const PerfectPlaces = ({obj }) => {
    return (
        <div className="col-lg-4 col-md-6">
            <Link to={`/search?state=${obj._id}`} >
                <div className="card">
                    <div className="overlay"></div>
                    <img src={getImageURL(obj.image)} alt={obj.name} className="img-fluid" />
                    <div className="card-body">
                        <h4>{obj.name}</h4>
                        <p></p>
                        <i className="fa fa-angle-right"></i>
                    </div>
                </div>
            </Link>
        </div>
    )
}


export default PerfectPlaces;