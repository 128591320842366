import React from 'react';
import { Link } from 'react-router-dom';
var base = "https://homehunt-admin.herokuapp.com";
class PropertyCard extends React.Component {

    render() {
        return (
            <div class="col-lg-4 col-md-4 col-sm-6">
                <div class="agent">
                    <div class="agent_img">
                        <a href="../../profile/9/en/kety_springs.html" title="Kety Springs">


                            <img src={base + this.props.obj.agentObj.profileImage} alt="Kety Springs" />
                        </a>
                        <div class="view-post">
                            <a href="../../profile/9/en/kety_springs.html" title="Kety Springs" class="view-posts">View Profile</a>
                        </div>
                    </div>
                    <div class="agent_info">
                        <h3><a href="../../profile/9/en/kety_springs.html" title="Kety Springs" class="">{this.props.obj.agentObj.name}</a></h3>
                        <span class="">Vinogradska 14</span>
                        <strong><i class="la la-phone"></i><span class="">{this.props.obj.agentObj.phoneNo}</span></strong>
                    </div>
                    <a href="../../profile/9/en/kety_springs.html" title="Kety Springs" class="ext-link"></a>
                </div>
            </div>


        )
    }
}

export default PropertyCard;