import React from 'react';
import { apiService } from '../_services/api.service';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    state = {
        setting: null
    }
    componentDidMount() {
        apiService.getAll("infosettings").then(settings => {
            this.setState({ setting: settings[0] })
        })
    }
    render() {
        let { setting } = this.state;
        return (
            <section className="bottom section-padding">
                <div className="container placeholder-container">
                    <div className="row">

                        <div className="col-xl-3 col-sm-6 col-md-4 widget_edit_enabled">
                            <div className="bottom-logo">
                                <img src="assets/img/logo.png" alt="Real estate point" className="img-fluid" />
                                <div className="content">
                                    <p className="description">{setting && setting.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-md-3 widget_edit_enabled">
                            <div className="widget-footer-contacts">
                                <h3>Contact Us</h3>
                                <ul className="footer-list">
                                    <li><i className="la la-map-marker"></i>
                                        <span className="value">{setting && setting.address}</span></li>
                                    <li><i className="la la-phone"></i> <span className="value"><a href={`tel:${setting && setting.phoneNo}`}>{setting && setting.phoneNo}</a></span></li>
                                    <li><i className="la la-envelope"></i> <span className="value"><a href={`mailto:${setting && setting.email}`}>{setting && setting.email}</a></span></li>
                                    <li><i className="la la-chevron-circle-right"></i><span className="value"><Link to='/contact'>Contact Us</Link></span></li>
                                    <li><i className="la la-user-secret"></i><span className="value"><Link to='/p/privacy-policy'>Privacy Policy</Link></span></li>
                                    <li><i className="la la-file"></i><span className="value"><Link to='/p/terms-of-use'>Terms of Use</Link></span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-6 col-sm-12 col-md-5 widget_edit_enabled">
                            <div className="bottom-list widget-follow-us">
                                <h3>Follow Us</h3>
                                <div className="footer-social">
                                    <a href={setting && setting.facebookLink} target="_blank"><i className="fa fa-facebook"></i></a>
                                    <a href={setting && setting.twitterLink} target="_blank"><i className="fa fa-twitter"></i></a>
                                    <a href={setting && setting.linkedinLink} target="_blank"><i className="fa fa-linkedin"></i></a>
                                    <a href={setting && setting.instagramLink} target="_blank"><i className="fa fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>              </div>
                    <img src="assets/img/placeholder.webp" alt="placeholder" className="footer-placeholder" />
                </div>
            </section>
        )
    }
}

export default Footer;