import React, { Component, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import "./style.css";
import LocationSearchInput from '../../components/LocationSearchInput';
import SearchBar from '../../components/SearchBar';
import setObjState from '../../_helpers/setObjState';
import { apiService } from '../../_services/api.service';
import PropertyCard from '../../components/PropertyCard';
import { APILoader } from '../../components/APILoader';
import withEvents from '../../_helpers/EventBus/withEvents';
class Search extends Component {

    state = {
        searchInitialValue: null,
        properties: [],
        loading: true
    }

    limit = 40;
    componentDidMount() {
        let query = new URLSearchParams(this.props.location.search);
        if (query.get("state")) {
            this.getViaState(query);
        }
    }
    UNSAFE_componentWillReceiveProps(props) {
        console.log({ UNSAFE_componentWillReceiveProps: true });
        let query = new URLSearchParams(props.location.search);
        const { history } = props;

        this.setState({ properties: [], loading: true })

        if (query.get("address") && query.get("lat") && query.get("lng")) {
            let searchAddress = { address: query.get("address"), latLng: { lat: query.get("lat"), lng: query.get("lng") } };
            let searchInitialValue = {
                searchAddress,
                purpose: query.get("purpose"),
                category: query.get("category"),
                "isPublished": true,
                "status": "Available"
            }

            if (query.get("featured")) {
                searchInitialValue["featured"] = true;
            }
            this.setState({ searchInitialValue });

            let rquery = JSON.parse(JSON.stringify(searchInitialValue));
            delete rquery.searchAddress;
            Object.keys(rquery).forEach(ik => {
                if (!rquery[ik]) {
                    delete rquery[ik];
                }
            });
            let coordinates = [parseFloat(searchAddress.latLng.lat), parseFloat(searchAddress.latLng.lng)];
            apiService.filterNear("properties", {
                coordinates,
                "maxDistance": 10 * 1000,
                "locationField": "addressLocation",
                "distanceField": "addressLocation",
                "query": rquery,
                "sortQuery": {
                    "dateTime": -1
                },
                "limit": this.limit
            })
                .then(properties => {
                    this.setState({ properties, loading: false })
                });

            return;
        }

        this.getViaState(query)

    }
    getViaState = (query) => {
        let sQuery = {};

        if (query.get("featured")) {
            sQuery["featured"] = true;
        }

        if (query.get("category")) {
            sQuery["category"] = true;
        }
        
        sQuery["state"] = query.get("state") ? query.get("state") : "5ed8000c6b767d001767f63d";

        console.log({ sQuery });

        apiService.filter("properties", {
            query: sQuery
        }).then(properties => {
            let searchAddress = { address: properties[0]?.stateObj?.name, latLng: null };
            let searchInitialValue = {
                searchAddress,
                purpose: "",
                category: query.get("category") ? query.get("category") : "",
                "isPublished": true,
                "status": "Available"
            }
            this.setState({ properties, loading: false, searchInitialValue })
        })

    }



    render() {

        const { searchInitialValue, properties, loading } = this.state;
        const { history, EventBus } = this.props;
        return (
            <div>
                <div className="p-5 top-bar">
                    {searchInitialValue &&
                        <SearchBar
                            label="Location"
                            history={history}
                            initialValue={searchInitialValue}
                        />
                    }
                </div>

                <div className="container pt-5 mb-5">
                    {!loading && <div className="mb-4">
                        <span>
                            Found Results: {properties.length}
                        </span>
                        <hr />
                    </div>}

                    <div className="row">
                        {
                            loading ?
                                <APILoader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={50}
                                    width={50}
                                // timeout={3000} //3 secs

                                /> :
                                properties.length == 0 ?
                                    <div className="container text-center p-5">
                                        <i class="fa fa-exclamation-triangle notfound-icon" aria-hidden="true"></i>
                                        <h2>No Property found</h2>
                                        <span>Try Again with different options.</span>

                                    </div>
                                    : properties.map((property, key) => <PropertyCard
                                        property={property}
                                        key={key}
                                        EventBus={EventBus}
                                    />)

                        }
                    </div>
                </div>


            </div >
        );
    }

}

export default Search;
