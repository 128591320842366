import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './pages/Home'
import Agents from './pages/Agents'
import Featured from './pages/Featured'
import Favorites from './pages/Favorites'
import About from './pages/About'
import Purchase from './pages/Purchase'
import Property_detail from './pages/Property_detail'
import Contact from './pages/Contact'
import Sign_in from './pages/Sign_in.js'
import StateProperties from './pages/StateProperties'
import Footer from './components/Footer'
import { BrowserRouter as Router, HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import States from './pages/States';
import Register from './pages/Register';

import { Provider } from "react-redux";
import { store } from './_helpers/store';
import { AnonymousRoute } from './_routeMiddleware/AnonymousRoute';
import EmailVerification from './pages/EmailVerification';
import EventProvider from './_helpers/EventBus/EventProvider';
import { Login } from './pages/Login';
import { AuthenticatedRoute } from './_routeMiddleware/AuthenticatedRoute';
import { MyProfile } from './pages/MyProfile';
import { MyProperties } from './pages/MyProperties';
import { Header } from './components/Header';
import { DataTable, Manage, Detail } from './components/CRUD';
import Page from './pages/Page';
import { apiService } from './_services/api.service';
import Search from './pages/Search';
function App() {
  const [infosettings, setInfosettings] = useState(null);
  useEffect(() => {
    apiService.getAll("infosettings").then(settings => {
      setInfosettings(settings[0]);
    })
  }, []);

  return (
    <Provider store={store}>
      <HashRouter>
        <Header infosettings={infosettings} />
        <Switch>
          <Route exact path='/property/:id' component={Property_detail} />
          <Route exact path='/' component={Home} />
          <Route exact path='/search' component={Search} />
          <Route exact path='/p/:slug' component={Page} />
          <Route exact path='/email/:type/:email' component={EmailVerification} />
          <Route exact path='/states' component={States} />
          <Route exact path='/featured' render={(props) => <Featured infosettings={infosettings} {...props} />} />
          <Route exact path='/favorites' render={(props) => <Favorites infosettings={infosettings} {...props} />} />
          <Route exact path='/about' render={(props) => <About infosettings={infosettings} {...props} />} />
          <Route exact path='/contact' render={(props) => <Contact infosettings={infosettings} {...props} />} />
          <Route exact path='/state/:id/properties' component={StateProperties} />

          <AnonymousRoute path="/register" name="RegisterPage" component={Register} />
          <AnonymousRoute path="/login" name="LoginPage" component={Login} />

          <AuthenticatedRoute path="/profile" exact name="MyProfile" component={MyProfile} />

          <AuthenticatedRoute path="/user/properties" exact component={DataTable} />
          <AuthenticatedRoute path="/user/property/add" exact component={Manage} />
          <AuthenticatedRoute path="/user/property/edit/:id" exact component={Manage} />
          <AuthenticatedRoute path="/user/properties/detail/:id" exact component={Detail} />
          <AuthenticatedRoute path="/user/properties/delete/:id" exact component={Detail} />

          <AuthenticatedRoute path="/user/propertyvisits" exact component={DataTable} />
          <AuthenticatedRoute path="/user/propertyvisits/detail/:id" exact component={Detail} />


          <AuthenticatedRoute path="/user/orders" exact component={DataTable} />
          <AuthenticatedRoute path="/user/orders/detail/:id" exact component={Detail} />

          <AuthenticatedRoute path="/user/orderstatuses" exact component={DataTable} />
          <AuthenticatedRoute path="/user/orderstatuses/detail/:id" exact component={Detail} />
          
          <AuthenticatedRoute path="/user/custom-notifications" exact component={DataTable} />
          <AuthenticatedRoute path="/user/custom-notifications/detail/:id" exact component={Detail} />
        </Switch>
        <Footer />
      </HashRouter>

    </Provider>

  );
}

export default App;
