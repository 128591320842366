import React from 'react';
import { Link } from 'react-router-dom';
import getImageURL from '../_helpers/imageHelper';
class Property_mainImg extends React.Component {

    render() {
        let {path}=this.props;
        return (
            <img  src={getImageURL(path)} alt="" />
        )
    }
}

export default Property_mainImg;