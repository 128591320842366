

import _config from '../_config';
import Axios from 'axios';
import { authHeader } from '../_helpers/auth-header';
import io from 'socket.io-client';
import config from '../_config';
// import JWTHelper from '../_helpers/jwt-helper';
export const authenticationService = {
    login: function (email, password) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password, ip: localStorage.getItem("ip") })
        };

        return fetch(`${_config.baseUrl}/api/auth/user/login`, requestOptions)
            .then(handleResponse)
            .then(res => {
                // debugger;
                res["ip"] = localStorage.getItem("ip");
                let socket = io(config.baseUrl, config.socketHeader);
                socket.emit("request", { action: "auth-changes", data:res });

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('token', JSON.stringify(res.token));
                return res.token;
            });
    },
    register: function (data) {
        return Axios.post(`${_config.baseUrl}/api/auth/user/register`, data).then(m => m.data);
    },
    sendVerificationEmail: function (email) {
        return Axios.post(`${_config.baseUrl}/api/auth/user/send-verification-email`, { email })
            .then(m => m.data);
    },

    getCurrentUser: function () {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        if (!requestOptions.headers["Authorization"]) {
            return new Promise((resolve, reject) => reject({ status: false, message: "User not logged In", tokenNotFound: true }))
        }

        return fetch(`${_config.baseUrl}/api/auth/user/`, requestOptions)
            .then(handleResponse);
    },

    logout: function () {
        // remove user from local storage to log user out
        localStorage.removeItem('token');
        localStorage.removeItem('flag');
    },


    update: function (data) {
        const requestOptions = {
            headers: authHeader()
        };
        console.log(`${_config.baseUrl}/api/auth/user/`, data, requestOptions);

        return Axios.put(`${_config.baseUrl}/api/auth/user/`, data, requestOptions).then(handleResponse1);
    },


};

function handleResponse1(response) {
    return response.data;
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                authenticationService.logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;

    });
}