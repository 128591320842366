import React, { Fragment, useEffect, useState } from 'react';
import { apiService } from '../_services/api.service';
import { favouritesService } from '../_services/favourite.service';
import PageBanner from '../components/PageBanner';
import { APILoader } from '../components/APILoader';
import getImageURL from '../_helpers/imageHelper';
import amountDirective from '../_helpers/amountDirective';
import Amenities from '../components/Amenities';
import Map from '../components/Map';

const Property_detail = ({ match }) => {
    const [property, setProperty] = useState(null);
    const [favouriteProperty, setFavouriteProperty] = useState(null);

    const { id } = match.params;
    const [selectedImage, setSelectedImage] = useState(null);


    useEffect(() => {
        setSelectedImage(null);
        apiService.getSingle("properties", id).then(setProperty);
    }, [id]);

    useEffect(() => {
        if (property) {
            console.log({ property });
            fetchFavouriteProperty();

            setSelectedImage(property.images.find(p => p.isPrimary));
        }
    }, [property]);



    const handleFavouritePress = () => {
        if (!favouriteProperty) {
            favouritesService.save(property);
        } else {
            favouritesService.remove(property._id)
        }
        setFavouriteProperty(!favouriteProperty);
    }

    const fetchFavouriteProperty = () => {
        let favouriteProperty = favouritesService.get(property._id);
        setFavouriteProperty(favouriteProperty);
    }
    return (
        <div>


            {property ? <Fragment>
                <PageBanner title={`Property ${property.name}`} />

                <div className='container'>
                    <div className='pd-image-container'>
                        <div className='pd-image-content' >
                            {selectedImage && <Fragment>
                                <div className='pd-selected-image'>
                                    <div
                                        style={{ backgroundImage: `url('${getImageURL(selectedImage.path)}')` }}
                                        className="pd-selected-image-img" alt="Main" />

                                </div>
                                <div className='pd-showcase-images'>

                                    {property.images && property.images.map((img, ikey) => <div
                                        onClick={() => setSelectedImage(img)}
                                        className={`pd-showcase-image`}
                                        style={{ backgroundImage: `${img._id === selectedImage._id ? 'linear-gradient(0deg, rgba(6, 15, 67, 0.7), rgba(233, 21, 38, 0.3)),' : ''} url('${getImageURL(img.path)}')` }}
                                        key={ikey} >
                                    </div>)}
                                </div>
                            </Fragment>}

                            <div className='pd-detail-container'>
                                <p className='pd-heading'>Details</p>
                                <div className="details-info">
                                    <ul>
                                        <li>
                                            <h4>Purpose:</h4>
                                            <span className="label label-success">{property.purpose}</span>
                                        </li>
                                        <li>
                                            <h4>Type:</h4>
                                            <span className="label label-success">{property.categoryObj.title} </span>
                                        </li>
                                        <li>
                                            <h4>County:</h4>
                                            <span className="label label-success"> Nigeria </span>
                                        </li>
                                        <li>
                                            <h4>City/Sate:</h4>
                                            <span className="label label-success"> {property.stateObj.name} </span>
                                        </li>

                                        <li>
                                            <h4>Area:</h4>
                                            <span className="label label-success">{property.areaSize} m2</span>
                                        </li>
                                        <li>
                                            <h4>Size:</h4>
                                            <span className="label label-success"> {property.areaSize} m2</span>
                                        </li>

                                        <li>
                                            <h4>Bathrooms:</h4>
                                            <span className="label label-success"> {property.bathrooms} </span>
                                        </li>
                                        <li>
                                            <h4>Bedrooms:</h4>
                                            <span className="label label-success"> {property.bedrooms} </span>
                                        </li>
                                        <li>
                                            <h4>Rooms:</h4>
                                            <span className="label label-success"> 7 </span>
                                        </li>
                                        <li>
                                            <h4>Sale price:</h4>
                                            <span className="label label-success">$ {property.salePrice} </span>
                                        </li>
                                        <li>
                                            <h4>Ownership:</h4>
                                            <span className="label label-success">{property.ownership}</span>
                                        </li>
                                        <li>
                                            <h4>Floor:</h4>
                                            <span className="label label-success"> {property.floors} </span>
                                        </li>

                                    </ul>
                                </div>
                            </div>


                            <div className='pd-detail-container'>
                                <p className='pd-heading'>Location</p>

                                <Map loc={property.addressLocation.coordinates} />
                            </div>

                            <div className='pd-detail-container mt-5'>
                                <p className='pd-heading'>Video</p>

                                <video className='pd-video' controls autoplay>
                                    {property.video !== "" && <source src={getImageURL(property.video)} type="video/mp4" />}
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                            <div className='pd-detail-container mt-5'>
                                <p className='pd-heading'>Description</p>

                                <div className="details-info pd-description-container">
                                    <p dangerouslySetInnerHTML={{ __html: property.detailDescription }}>
                                    </p>
                                </div>
                            </div>


                        </div>


                        <div className='pd-seller-section'>
                            <div className='pd-seller-container'>
                                <div className='pd-seller-section-content'>
                                    <p className='pd-property-name'>{property.name}</p>
                                    <p className='pd-property-address'><i className="la la-map-marker"></i>{property.address}</p>
                                    <ul className='pd-features'>
                                        <li className='pd-feature-item'>
                                            <i className='la la-bed mr-2'></i>
                                            {property.bathrooms} Bathrooms
                                        </li>
                                        <li className='pd-feature-item'>
                                            <i className='la la-tag mr-2'></i>
                                            {property.bedrooms} Bedrooms
                                        </li>
                                        <li className='pd-feature-item'>
                                            <i className='la la-th mr-2'></i>
                                            {property.areaSize} meter square
                                        </li>
                                    </ul>
                                </div>
                                <div className='pd-price-section'>
                                    <p className='pd-price'> ₦{amountDirective(property.purpose === "Sale" ? property.salePrice : property.rentPrice)}</p>
                                    <p className="pd-purpose">For {property.purpose}</p>

                                    <div className='favourite-section' onClick={handleFavouritePress}>
                                        <i className={`favourite-icon la la-heart${favouriteProperty ? ' active' : '-o'}`}></i>
                                    </div>
                                </div>
                            </div>

                            <div className='pd-seller-details'>
                                <div className='pd-seller-image-container'>
                                    <div className='pd-seller-image' style={{ backgroundImage: `url('${getImageURL(property.userObj.profileImage)}')` }}></div>
                                </div>
                                <div className='pd-seller-info'>
                                    <p className='pd-seller-name'>@{property.userObj.name}</p>
                                    <p className='pd-seller-ownership'>{property.ownership}</p>
                                </div>

                                <div className='pd-listing-date'>
                                    <p>{new Date(property.dateTime).toDateString()}</p>
                                </div>
                            </div>


                            <p className='pd-heading pd-amentities-heading'>Amenities</p>
                            <div className='pd-amentities-section details-info'>
                                {property.amenities.filter(d => d.obj.type === "Outdoor").length > 0 && <Fragment>
                                    <h3>Outdoor</h3>

                                    <div className="row pd-amentities-container">

                                        {
                                            property.amenities.filter(d => d.obj.type === "Outdoor")
                                                .map((d, index) => <Amenities key={d._id} title={d.obj.title} />)
                                        }

                                    </div>
                                </Fragment>}


                                {property.amenities.filter(d => d.obj.type === "Indoor").length > 0 && <Fragment>
                                    <h3 className='pd-amenity-indoor'>Indoor</h3>
                                    <div className="row pd-amentities-container">

                                        {
                                            property.amenities.filter(d => d.obj.type === "Indoor")
                                                .map((d, index) => <Amenities key={d._id} title={d.obj.title} />)
                                        }

                                    </div>
                                </Fragment>}
                            </div>

                            <div className='pd-detail-container'>
                                <p className='pd-heading'>Floor Plan</p>
                                <img src={getImageURL(property.planImage)} alt="floorplan" className='pd-floorplan' />

                            </div>



                        </div>
                    </div>

                </div>
            </Fragment>
                :
                <APILoader />
            }

        </div>
    );
};

export default Property_detail;