import React from 'react';
import { APILoader } from '../components/APILoader';
import PageBanner from '../components/PageBanner';
import PropertyCard from '../components/PropertyCard';
import { favouritesService } from '../_services/favourite.service';
import io from 'socket.io-client';
import config from '../_config';

let socket = io(config.baseUrl, config.socketHeader);
class Favorites extends React.Component {
    state = {
        properties: null
    }
    componentDidMount() {
        this.fetch();
        socket.on("changes-favourite", dt => {
            if (dt.ip == localStorage.getItem("ip")) {
                this.fetch();
            }
        });
    }
    fetch = () => {
        this.setState({ properties: favouritesService.getAll() });
    }
    render() {
        const { infosettings } = this.props;
        const { properties } = this.state;
        return (
            <div>
                <div >
                    <PageBanner title="Favourites" />
                    <section className="popular-listing hp2 section-padding widget_edit_enabled">
                        <div className="container">
                            <div className="row">

                                {
                                    properties ?
                                        properties.map((property, key) => <PropertyCard
                                            property={property}
                                            key={key} />)
                                        :
                                        <APILoader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={50}
                                            width={50}
                                        // timeout={3000} //3 secs

                                        />
                                }
                                {properties && properties.length == 0 &&
                                    <div className="container text-center">
                                        <i className="fa fa-info-circle text-info" aria-hidden="true" style={{ fontSize: 70 }}></i>
                                        <br />
                                        <br />
                                        <span>No favourite properties</span>
                                    </div>}

                            </div>
                        </div>
                    </section>
                    <a href="#" title="" className="widget_edit_enabled">
                        <section className="cta section-padding">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="cta-text">
                                            <h2>Discover a home you'll love to stay</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </a>
                </div>


                <div className="se-pre-con"></div>
            </div>
        )
    }
}

export default Favorites;