import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Header } from '../../components/Header';
import { connect } from 'react-redux';
import withEvents from '../../_helpers/EventBus/withEvents';
import { authenticationActions } from '../../_actions/authentication.actions';
import Notification from '../../components/notification';
import { ToastContainer, toast } from 'react-toastify';
import Field from '../../components/field';
import PasswordField from '../../components/PasswordField';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import validate from 'validate.js';
import getImageURL from '../../_helpers/imageHelper';
import { authenticationService } from '../../_services/authentication.service';
import PageBanner from '../../components/PageBanner';
import io from 'socket.io-client';
import config from '../../_config';

class MyProfile extends React.Component {
    state = {
        formObj: null,
        validationRule: null,
        errors: {},
        formError: null,
        loaded: 0,
        pageLoading: true,
        isLoading: true,
        modelType: {
            bluePrint: {
                'name': {
                    type: String,
                    label: "Name",
                    placeholder: "Enter Name",
                    dataType: "String",
                    displayOnHeader: true,
                    default: "",
                    required: true
                },
                'phoneNo': {
                    type: String,
                    label: "Phone Number",
                    placeholder: "Enter Phone Number",
                    dataType: "String",
                    displayOnHeader: true,
                    default: "",
                    required: true
                },
                'username': {
                    type: String,
                    label: "Username",
                    placeholder: "Enter Username",
                    dataType: "String",
                    displayOnHeader: true,
                    default: "",
                    required: true
                },

                'email': {
                    type: String,
                    label: "Email",
                    placeholder: "Enter Email",
                    dataType: "String",
                    displayOnHeader: true,
                    default: "",
                    required: true,
                    email: true
                },
                'password': {
                    type: String,
                    label: "Password",
                    placeholder: "Enter Password",
                    dataType: "PasswordHash",
                    displayOnHeader: false,
                    default: "",
                    // required: true
                },
            }
        },
        single: false
    }

    componentDidMount() {
        let socket = io(config.baseUrl, config.socketHeader);
        if (localStorage.getItem("flag")) {
            this.fetchData();
        } else {
            socket.on("auth-changes", (m) => {
                if (localStorage.getItem("ip") == m.ip) {
                    setTimeout(() => {
                        this.fetchData();
                    }, 2000);
                }
            });
        }

    }
    fetchData = () => {
        let { user } = authenticationActions.getCurrentUserLocal();
        let { modelType } = this.state;
        let validationRule = {};
        Object.keys(modelType.bluePrint).forEach((attribute, key) => {
            if (!modelType.bluePrint[attribute].hidden && (modelType.bluePrint[attribute].dataType !== "Boolean" || modelType.bluePrint[attribute].dataType !== "DefaultDate")) {
                validationRule[attribute] = (modelType.bluePrint[attribute].required) ? { presence: { allowEmpty: false } } : null;
                validationRule[attribute] = (modelType.bluePrint[attribute].required && modelType.bluePrint[attribute].email) ? { presence: { allowEmpty: false }, email: { message: "Doesn't look like a valid email" } } : null;
            }
        });
        let formObj = user;
        this.setState({ validationRule, formObj, isLoading: false });
    }

    handleChange({ target }) {
        const { name, value } = target;
        const errors = validate({ [name]: value }, { [name]: this.state.validationRule[name] });
        let obj = this.fillInObject(Object.assign({}, this.state.formObj), name, value);
        this.setState({
            formObj: obj,
            errors: Object.assign({}, this.state.errors,
                errors ? errors : { [name]: undefined })
        });
    }
    handleChageCustom = (name, value) => {
        const errors = validate({ [name]: value }, { [name]: this.state.validationRule[name] });
        let obj = this.fillInObject(Object.assign({}, this.state.formObj), name, value);
        this.setState({
            formObj: obj,
            errors: Object.assign(
                {},
                this.state.errors,
                errors ? errors : { [name]: undefined }
            )
        });
    }
    fillInObject(obj, name, value) {
        obj[name] = value;
        return obj;
    }
    checkMimeType = (event) => {
        //getting file object
        let files = event.target.files
        //define message container
        let err = []
        // list allow mime type
        const types = ['image/png', 'image/jpeg', 'image/gif']
        // loop access array
        for (var x = 0; x < files.length; x++) {
            // compare file type find doesn't matach
            if (types.every(type => files[x].type !== type)) {
                // create error message and assign to container   
                err[x] = files[x].type + ' is not a supported format\n';
            }
        };
        for (var z = 0; z < err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
        return (err.length > 0) ? false : true;
    }
    maxSelectFile = (event) => {
        let files = event.target.files
        if (files.length > 1) {
            const msg = 'Only 1 images can be uploaded at a time'
            event.target.value = null
            toast.warn(msg)
            return false;
        }
        return true;
    }
    checkFileSize = (event) => {
        let files = event.target.files
        let size = 2000000
        let err = [];
        for (var x = 0; x < files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type + 'is too large, please pick a smaller file\n';
            }
        };
        for (var z = 0; z < err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
        return true;
    }

    onChangeHandler = event => {
        var files = event.target.files;
        if (this.maxSelectFile(event) && this.checkMimeType(event) && this.checkFileSize(event)) {
            try {


                let reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = (e) => {
                    let file = e.target.result;

                    let obj = this.fillInObject(Object.assign({}, this.state.formObj), "profileImage", file);
                    obj.selectedImage = files[0];

                    this.setState({
                        formObj: obj
                    });
                }
            } catch (error) {
                toast.error('Invalid Image')
            }
        }
    }
    handleSubmit(e) {
        e.preventDefault();
        const errors = validate(this.state.formObj, this.state.validationRule);
        debugger;
        if (errors) {
            toast.error("Form data is Invalid")
            return this.setState({ formError: null, errors });
        }


        this.setState({ isLoading: true }, () => {

            let form = new FormData();
            if (this.state.formObj.selectedImage) {
                form.append('profileImage', this.state.formObj.selectedImage)
            }
            form.append('name', this.state.formObj.name);
            form.append('email', this.state.formObj.email);
            form.append('phoneNo', this.state.formObj.phoneNo);
            form.append('username', this.state.formObj.username);
            form.append('password', this.state.formObj.password);
            if (this.state.formObj._id) {
                authenticationService.update(form).then(res => {
                    this.setState({ isLoading: false }, () => {
                        toast.success("Profile Updated");
                        this.props.dispatch(authenticationActions.getCurrentUser())
                    })
                }).catch(err => {
                    this.setState({ isLoading: false }, () => {
                        toast.error(err.message);
                    });
                })

            }
        })
    }
    render() {

        const { formObj, isLoading, errors, modelType } = this.state;
        return (
            <div>
                <div>
                    <PageBanner title="My Profile" />
                    <div className="container m-padding" id='main'>
                        <div className="widget-panel border  widget-submit">
                            <div className="widget-header header-styles">
                                <h2 className="title">Profile</h2>
                            </div> {/* ./ title */}
                            <div className="validation m25">
                            </div>
                            <form className="form-estate" onSubmit={e => this.handleSubmit(e)}>
                                <ToastContainer></ToastContainer>
                                {this.state.formError ? (
                                    <Notification type="danger" onCloseBtnClick={e => this.setState({ formError: null })}>
                                        {this.state.formError}
                                    </Notification>
                                ) : null}

                                <div className="row">
                                    <div className="col-sm-3">
                                        {
                                            formObj && <div>
                                                <div className="text-center">
                                                    <img src={getImageURL(formObj.profileImage)} style={{ height: 'auto', width: '100%' }} alt="User" />

                                                </div>
                                                <div className="form-group files">
                                                    <label>Upload Profile Image </label>
                                                    <input type="file" className="form-control" name="file" onChange={this.onChangeHandler} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-sm-6">
                                        {
                                            formObj && Object.keys(modelType.bluePrint).map((attribute, key) => {
                                                if (!modelType.bluePrint[attribute].hidden && modelType.bluePrint[attribute].dataType !== "DefaultDate") {
                                                    if (modelType.bluePrint[attribute].dataType === "String" || modelType.bluePrint[attribute].dataType === "Number") {
                                                        return <Field label={modelType.bluePrint[attribute].label} errors={errors[attribute]} key={key}>
                                                            <input
                                                                name={attribute}
                                                                type={modelType.bluePrint[attribute].dataType === "String" ? "text" : "number"}
                                                                placeholder={modelType.bluePrint[attribute].placeholder}
                                                                value={formObj[attribute]}
                                                                disabled={isLoading}
                                                                onChange={e => this.handleChange(e)}
                                                            />
                                                        </Field>
                                                    }
                                                    else if (modelType.bluePrint[attribute].dataType === "PasswordHash") {
                                                        return <Field label={modelType.bluePrint[attribute].label} errors={errors[attribute]} key={key}>
                                                            <PasswordField
                                                                name={attribute}
                                                                // type={modelType.bluePrint[attribute].dataType === "String" ? "text" : "number"}
                                                                placeholder={modelType.bluePrint[attribute].placeholder}
                                                                value={formObj[attribute]}
                                                                disabled={isLoading}
                                                                onChange={value => this.handleChageCustom(attribute, value)}
                                                            />
                                                        </Field>
                                                    } else {
                                                        return null;
                                                    }
                                                } else {
                                                    return null;
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                                <hr />
                                {formObj &&
                                    <div className="form-group">
                                        <div className="controls">
                                            <input type="submit" name="submit" value="Save" className="btn btn-primary" /> </div>
                                    </div>
                                }
                            </form>
                        </div>
                        {/* ./ widget-submit */}



                    </div>
                </div>


                <div className="se-pre-con"></div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    const { alert } = state;
    return {
        loggingIn,
        alert,
    };
}

const connected = connect(mapStateToProps)(withRouter(withEvents(MyProfile)));
export { connected as MyProfile };