import React from 'react';
import { Link } from 'react-router-dom';
class Sign_in extends React.Component {

    render() {
        return (
            <div>

                <div >
                    <header>
                        <div className="wpart">

                            <div className="top-wrapper">

                            </div>

                            <div className="top-header widget_edit_enabled">
                                <div className="container">
                                    <div className="row justify-content-between">

                                        <div className="col-xl-7 col-md-7 col-sm-12">



                                            <div className="header-address">

                                                <a href="tel://38501123321">
                                                    <i className="la la-phone-square"></i>
                                                    <span> +385 (0)1 123 321</span>
                                                </a>
                                                <a href="mailto:info@my-website.com">
                                                    <i className="la la-envelope-o"></i>
                                                    <span>info@my-website.com</span>
                                                </a>
                                                <a href="index.php/frontend/login/en.html#content" className="login_popup_enabled"><i className="la la-user"></i> <span>Login</span></a>




                                            </div>
                                        </div>


                                        <div className="col-xl-3 col-lg-2 col-md-2 col-sm-6 col-6">
                                            <div className="header-social">
                                                <a href="#">
                                                    <i className="fa fa-facebook"></i>
                                                </a>
                                                <a href="#">
                                                    <i className="fa fa-twitter"></i>
                                                </a>
                                                <a href="#">
                                                    <i className="fa fa-instagram"></i>
                                                </a>
                                                <a href="#">
                                                    <i className="fa fa-linkedin"></i>
                                                </a>
                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </div>
                            <div className="header widget_edit_enabled">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <nav className="navbar navbar-expand-lg navbar-light">
                                                <a href="../../../index.html" className="navbar-brand">
                                                    <img src="assets/img/logo.png" alt="Home Hunt" />
                                                </a>

                                                <button className="menu-button" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                                                    <span className="icon-spar"></span>
                                                    <span className="icon-spar"></span>
                                                    <span className="icon-spar"></span>
                                                </button>
                                                <div className="navbar-collapse" id="navbarSupportedContent">
                                                    <ul className="navbar-nav mr-auto" id="main-menu">
                                                        <li className="nav-item ">
                                                            <Link to='/' className="nav-link">Home</Link>

                                                        </li>

                                                        <li className="nav-item active">
                                                            <Link to='/Agents' className="nav-link">Agents</Link></li>
                                                        <li className="nav-item ">
                                                            <Link to='/Featured' className="nav-link">Featured</Link></li>
                                                        <li className="nav-item ">
                                                            <Link to='/About' className="nav-link">About</Link>
                                                        </li>
                                                        <li className="nav-item "><Link to='/Contact' className="nav-link">Contact</Link></li>
                                                    </ul>
                                                    <div className="d-inline my-2 my-lg-0">
                                                        <ul className="navbar-nav">
                                                            <li className="nav-item signin-btn">

                                                                <span className="nav-link">
                                                                    <i className="la la-sign-in"></i>
                                                                    <span>
                                                                        <a href="../../frontend/login/en.html#sw_login" className="login_popup_enabled ">
                                                                            <b className="signin-op">Sign in</b>
                                                                        </a>
                                                                        or    <a href="../../frontend/login/en.html#sw_register" className="">
                                                                            <b className="reg-op">Register</b>
                                                                        </a>
                                                                    </span>

                                                                </span>
                                                            </li>

                                                        </ul>

                                                    </div>
                                                    <a href="#" title="" className="close-menu"><i className="la la-close"></i></a>
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>                        </div>



                    </header>

                    <div >
                        <h3>Sign In to your Account</h3>
                        <div className="popup-form form-wr">
                            <form id="popup_form_login">
                                <div className="alert alert-success m0" role="alert">
                                    <b>Demo login details for Admin:</b><br />
                                    Username: admin<br />
                                    Password:  admin<br /><br />
                                    <b> Demo login details for User:</b><br />
                                    Username:  user<br />
                                    Password:  user                </div>
                                <div className="alerts-box"></div>
                                <div className="form-field">
                                    <input type="text" name="username" value="" className="form-control" id="inputUsername" placeholder="Username" />            </div>
                                <div className="form-field">
                                    <input type="password" name="password" value="" className="form-control" id="inputPassword" placeholder="Password" />            </div>
                                <div className="form-cp">
                                    <div className="form-field">
                                        <div className="input-field">
                                            <input type="checkbox" name="remember" id="remember" value="true" />
                                            <label for="remember">
                                                <span></span>
                                                <small>Remember me</small>
                                            </label>
                                        </div>
                                    </div>
                                    <a href="index.php/admin/user/forgetpassword.html" className="forgot-password" title="Forgot Password?">Forgot Password?</a>
                                </div>
                                <button type="submit" className="btn2">Sign In</button>
                            </form>
                            <a href="index.php/frontend/login/en.html#sw_register" className="link-bottom">Create new account</a>
                            <a href="#" className="fb-btn"><i className="fa fa-facebook" aria-hidden="true"></i>Sign in with facebook</a>
                        </div>
                    </div>



                </div>

            </div>
        )
    }
}

export default Sign_in;