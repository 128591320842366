import React from 'react';
import { Link } from 'react-router-dom';
import { APILoader } from '../components/APILoader';
import { Header } from '../components/Header';
import PageBanner from '../components/PageBanner';
import PropertyCard from '../components/PropertyCard';

import { apiService } from '../_services/api.service';

class Featured extends React.Component {
    state = {
        properties: null
    }
    componentDidMount() {
        this.getProperties();
    }
    getProperties = () => {
        apiService.filter("properties", { "query": { "featured": true }, "limit": 3 }).then(properties => {
            this.setState({ properties })
        })
    }
    render() {
        const { infosettings,EventBus } = this.props;
        const { properties } = this.state;
        return (
            <div>
                <div >
                    <PageBanner title="Featured" />
                    <section className="popular-listing hp2 section-padding widget_edit_enabled">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-6">
                                    <div className="section-heading">
                                        <span>Discover</span>
                                        <h3>Featured Listing</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                {
                                    properties ?
                                        properties.map((property, key) => <PropertyCard
                                            property={property}
                                            EventBus={EventBus}
                                            key={key} />)
                                        :
                                        <APILoader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={50}
                                            width={50}
                                        // timeout={3000} //3 secs

                                        />
                                }
                                <div className="col-lg-12">
                                    <div className="load-more-posts">
                                        <a href="../145.html" title="" className="btn2">Load More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <a href="#" title="" className="widget_edit_enabled">
                        <section className="cta section-padding">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="cta-text">
                                            <h2>Discover a home you'll love to stay</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </a>
                </div>


                <div className="se-pre-con"></div>
            </div>
        )
    }
}

export default Featured;