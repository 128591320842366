import React from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import validate from 'validate.js';
import Field from '../../components/field';
import PasswordField from '../../components/PasswordField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notification from '../../components/notification';
import { authenticationService } from '../../_services/authentication.service';
import { Header } from '../../components/Header';



export default class Register extends React.Component {
    state = {
        formObj: null,
        validationRule: null,
        errors: {},
        formError: null,
        loaded: 0,
        pageLoading: true,
        isLoading: true,
        modelType: {
            bluePrint: {
                'name': {
                    type: String,
                    label: "Name",
                    placeholder: "Enter Name",
                    dataType: "String",
                    displayOnHeader: true,
                    default: "",
                    required: true
                },
                'phoneNo': {
                    type: String,
                    label: "Phone Number",
                    placeholder: "Enter Phone Number",
                    dataType: "String",
                    displayOnHeader: true,
                    default: "",
                    required: true
                },
                'username': {
                    type: String,
                    label: "Username",
                    placeholder: "Enter Username",
                    dataType: "String",
                    displayOnHeader: true,
                    default: "",
                    required: true
                },

                'email': {
                    type: String,
                    label: "Email",
                    placeholder: "Enter Email",
                    dataType: "String",
                    displayOnHeader: true,
                    default: "",
                    required: true,
                    email: true
                },
                'password': {
                    type: String,
                    label: "Password",
                    placeholder: "Enter Password",
                    dataType: "PasswordHash",
                    displayOnHeader: false,
                    default: "",
                    required: true
                },
            }
        },
        single: false
    }

    componentDidMount() {
        let { modelType } = this.state;
        let validationRule = {};
        Object.keys(modelType.bluePrint).forEach((attribute, key) => {
            if (!modelType.bluePrint[attribute].hidden && (modelType.bluePrint[attribute].dataType !== "Boolean" || modelType.bluePrint[attribute].dataType !== "DefaultDate")) {
                validationRule[attribute] = (modelType.bluePrint[attribute].required) ? { presence: { allowEmpty: false } } : null;
                validationRule[attribute] = (modelType.bluePrint[attribute].required && modelType.bluePrint[attribute].email) ? {
                    presence: { allowEmpty: false },
                    email: {
                        message: "Doesn't look like a valid email"
                    }
                } : null;               
            }
        });

        let formObj = {
            _id: ""
        }
        Object.keys(modelType.bluePrint).forEach((attribute, key) => {
            if (!modelType.bluePrint[attribute].hidden && (modelType.bluePrint[attribute].dataType === "Date" || modelType.bluePrint[attribute].dataType === "DefaultDate")) {
                formObj[attribute] = new Date();
            } else {
                formObj[attribute] = modelType.bluePrint[attribute].default;
            }
        });
        this.setState({ validationRule, formObj, isLoading: false });
    }

    handleChange({ target }) {
        const { name, value } = target;
        const errors = validate({ [name]: value }, { [name]: this.state.validationRule[name] });
        let obj = this.fillInObject(Object.assign({}, this.state.formObj), name, value);
        this.setState({
            formObj: obj,
            errors: Object.assign(
                {},
                this.state.errors,
                errors ? errors : { [name]: undefined }
            )
        });
    }
    handleChageCustom = (name, value) => {
        const errors = validate({ [name]: value }, { [name]: this.state.validationRule[name] });
        let obj = this.fillInObject(Object.assign({}, this.state.formObj), name, value);
        this.setState({
            formObj: obj,
            errors: Object.assign(
                {},
                this.state.errors,
                errors ? errors : { [name]: undefined }
            )
        });
    }
    fillInObject(obj, name, value) {
        obj[name] = value;
        return obj;
    }
    handleSubmit(e) {
        e.preventDefault();
        let { formObj, validationRule } = this.state;
        let { history } = this.props;
        const errors = validate(formObj, validationRule);
        if (errors) {
            toast.error("Form data is Invalid")
            return this.setState({ formError: null, errors });
        }

        this.setState({ isLoading: true }, () => {
            authenticationService.register(formObj)
                .then(m => {
                    console.log(m);
                    history.push(`/email/register-success/${m.email}`)

                })
                .catch(err => {
                    toast.error(err.response.data.message);
                    this.setState({ isLoading: false })
                })
        })
    }


    render() {
        const { formObj, isLoading, errors, modelType } = this.state;
        return (
            <div>
                <main className="main-clear">
                    <div className="selio_sw_win_wrapper">
                        <div className="ci sw_widget sw_wrap">
                            <div className="sign-form-wr">
                                <div className="sign-form-inner tab-content">
                                    <div className="form-wr sign-up-form" role="tabpanel" id="sign-up-form">
                                        <h3>Register</h3>
                                        <div className="form-wr-content">
                                            <form onSubmit={e => this.handleSubmit(e)} >
                                                <ToastContainer></ToastContainer>
                                                {this.state.formError ? (
                                                    <Notification type="danger" onCloseBtnClick={e => this.setState({ formError: null })}>
                                                        {this.state.formError}
                                                    </Notification>
                                                ) : null}
                                                {
                                                    formObj && Object.keys(modelType.bluePrint).map((attribute, key) => {
                                                        if (!modelType.bluePrint[attribute].hidden && modelType.bluePrint[attribute].dataType !== "DefaultDate") {
                                                            if (modelType.bluePrint[attribute].dataType === "String" || modelType.bluePrint[attribute].dataType === "Number") {
                                                                return <Field label={modelType.bluePrint[attribute].label} errors={errors[attribute]} key={key}>
                                                                    <input
                                                                        name={attribute}
                                                                        type={modelType.bluePrint[attribute].dataType === "String" ? "text" : "number"}
                                                                        placeholder={modelType.bluePrint[attribute].placeholder}
                                                                        value={formObj[attribute]}
                                                                        disabled={isLoading}
                                                                        onChange={e => this.handleChange(e)}
                                                                    />
                                                                </Field>
                                                            }
                                                            else if (modelType.bluePrint[attribute].dataType === "PasswordHash") {
                                                                return <Field label={modelType.bluePrint[attribute].label} errors={errors[attribute]} key={key}>
                                                                    <PasswordField
                                                                        name={attribute}
                                                                        // type={modelType.bluePrint[attribute].dataType === "String" ? "text" : "number"}
                                                                        placeholder={modelType.bluePrint[attribute].placeholder}
                                                                        value={formObj[attribute]}
                                                                        disabled={isLoading}
                                                                        onChange={value => this.handleChageCustom(attribute, value)}
                                                                    />
                                                                </Field>
                                                            } else {
                                                                return null;
                                                            }
                                                        } else {
                                                            return null;
                                                        }
                                                    })
                                                }

                                                <button type="submit" className="btn2" disabled={isLoading}>{isLoading ? "Please Wait.." : "Create Account"}</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        );

    }
}