import React, { Component, useEffect, useState } from 'react';
import { apiService } from '../../_services/api.service';
import LocationSearchInput from '../LocationSearchInput';
import "./style.css";

const SearchBar = ({ label, initialValue, history }) => {
    const [value, setValue] = useState({
        searchAddress: {
            address: "",
            latLng: null
        },
        purpose: "",
        category: ""
    });
    const [categories, setCategories] = useState([])
    useEffect(() => {
        initialValue && setValue(initialValue);

        apiService.filter('categories', { query: { status: true }, sort: { dateTime: 1 } }).then(c => {
            setCategories(c);
        })
    }, [])
    const doSearch = () => {
        const { searchAddress, purpose, category } = value;
        let url = `/search?address=${searchAddress.address}&lat=${searchAddress.latLng.lat}&lng=${searchAddress.latLng.lng}`;
        if (purpose) {
            url += `&purpose=${purpose}`;
        }
        if (category) {
            url += `&category=${category}`;
        }
        url += `&time=${new Date().getTime()}`;
        history && history.push(url)
    }
    const doChange = (name, vl) => {
        let tempValue = JSON.parse(JSON.stringify(value));
        tempValue[name] = vl;
        setValue(tempValue);
    }

    return (
        value ?
            <form className="row banner-search search-form top-search">
                <div className="form_field col-sm-6 pt-2">
                    <div className="form-group  field_search_4 ">
                        <LocationSearchInput searchAddress={value.searchAddress}
                            label={label}
                            onChange={(v) => doChange('searchAddress', v)} />
                    </div>
                </div>
                <div className="form_field col-sm-2 pt-1">
                    <select className="form-control"
                        value={value.category}
                        onChange={({ target }) => doChange('category', target.value)}
                    >
                        <option>{categories.length == 0 && value.category ? "Loading..." : "Category"}</option>
                        {categories.map((category, key) => <option value={category._id} key={key}>{category.title}</option>)}
                    </select>
                </div>
                <div className="form_field col-sm-2 pt-1">
                    <select className="form-control"
                        value={value.purpose}
                        onChange={({ target }) => doChange('purpose', target.value)}
                    >
                        <option >Purpose</option>
                        <option value="Sale">Sale</option>
                        <option value="Rent">Rent</option>
                    </select>
                </div>

                <div className="form_field col-sm-2 srch-btn">
                    <button
                        onClick={doSearch}
                        className="btn btn-outline-primary sw-search-start"
                        disabled={!value.searchAddress.latLng}
                    >
                        <i className="la la-search"></i>
                        <span>Search</span>
                        <i className="fa fa-spinner fa-spin fa-ajax-indicator hidden"></i>
                    </button>
                </div>
            </form> : null
    );

}

export default SearchBar;
