import React from 'react';
import { Link } from 'react-router-dom';
import PropertyCard from '../components/PropertyCard'
import axios from 'axios';
import PerfectPlaces from '../components/PerfectPlaces';
import { apiService } from '../_services/api.service';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import { APILoader } from '../components/APILoader';
import { Header } from '../components/Header';
import './Home.css';
import LocationSearchInput from '../components/LocationSearchInput';
import SearchBar from '../components/SearchBar';
import setObjState from '../_helpers/setObjState';

class Home extends React.Component {
    state = {
        properties: null,
        categoryWiseProperties: null,
        data: [],
        img: [],
        states: null,
    }
    componentDidMount() {
        this.getProperties();
        this.getStates();
        this.getCategoryWiseProperties();
    }

    getProperties = () => {
        apiService.filter("properties", { "query": { "featured": true }, "limit": 3 }).then(properties => {
            this.setState({ properties })
        })
    }

    getStates = () => {
        apiService.filter("states", { "query": { "featured": true }, "limit": 3 }).then(states => {
            this.setState({ states })
        })
    }

    getCategoryWiseProperties = async () => {
        const { categoryWiseProperties } = this.state;
        if (!categoryWiseProperties) {
            const categories = await apiService.filter("categories", { query: {}, sortQuery: { dateTime: 1 } })
            let mCategoryWiseProperties = [];
            for (let i = 0; i < categories.length; i++) {
                const category = categories[i];
                let mProperties = await apiService.filter("properties", { query: { category: category._id, isPublished: true, status: "Available" }, sortQuery: { dateTime: -1, }, limit: 3 })
                mCategoryWiseProperties.push({ category, properties: mProperties });
            }

            this.setState({ categoryWiseProperties: mCategoryWiseProperties });
        }

    }


    render() {
        const { properties, states, categoryWiseProperties } = this.state;
        const { history, EventBus } = this.props;
        return (
            <div>
                <div >
                    <div className="wpart">
                        <section className="banner widget_edit_enabled" style={{ marginTop: '15px' }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="banner-content">
                                            <h1>Find Your Dream Home</h1>
                                        </div>
                                        <SearchBar label="Location" history={history} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <section className="popular-listing hp2 section-padding widget_edit_enabled">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-6">
                                    <div className="section-heading">
                                        <span>Discover</span>
                                        <h3>Popular Listing</h3>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {/* {this.state.data.map(function (d, index) {
                                        var x = "";
                                        for (var i = 0; i < d.images.length; i++) {
                                            if (d.images[i].isPrimary) {
                                                x = d.images[i].path;
                                            }
                                        }
                                        return <PrpertyCard key={d._id} path={x} obj={d} />

                                    })} */}

                                {
                                    properties ?
                                        properties.map((property, key) => <PropertyCard
                                            property={property}
                                            key={key}
                                            EventBus={EventBus}
                                        />)
                                        :
                                        <APILoader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={50}
                                            width={50}
                                        // timeout={3000} //3 secs

                                        />
                                }

                            </div>
                        </div>
                    </section>

                    {categoryWiseProperties && categoryWiseProperties.length > 0 && <div>
                        {categoryWiseProperties.map(({ category, properties }, key) => {
                            return properties.length > 0 ? <section className="popular-listing hp2 section-padding widget_edit_enabled" key={key}>
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-xl-6">
                                            <div className="section-heading">
                                                <span>Featured</span>
                                                <h3>{category.title}</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {
                                            properties ?
                                                properties.map((property, key) => <PropertyCard
                                                    property={property}
                                                    key={key}
                                                    EventBus={EventBus}
                                                />)
                                                :
                                                <APILoader
                                                    type="Puff"
                                                    color="#00BFFF"
                                                    height={50}
                                                    width={50}
                                                // timeout={3000} //3 secs

                                                />
                                        }

                                    </div>
                                </div>
                            </section> : null
                        })}

                    </div>}

                    <section className="explore-feature hp7 section-padding widget_edit_enabled">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-6">
                                    <div className="section-heading">
                                        <span>Explore Features</span>
                                        <h3>Service You Need</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-3 col-sm-6 col-md-6 col-lg-4">
                                    <a href="#" title="">
                                        <div className="card">
                                            <div className="card-body">
                                                <i className="icon-home"></i>
                                                <h3>Perfect Tools</h3>
                                                <p>Aenean sollicitudin, lorem quis bibendum auctor, nisi elit conseat ipsum, nec sagittis sem nibh.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-xl-3 col-sm-6 col-md-6 col-lg-4">
                                    <a href="#" title="">
                                        <div className="card">
                                            <div className="card-body">
                                                <i className="icon-cursor"></i>
                                                <h3>Search in Click</h3>
                                                <p>Aenean sollicitudin, lorem quis bibendum auctor, nisi elit conseat ipsum, nec sagittis sem nibh.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-xl-3 col-sm-6 col-md-6 col-lg-4">
                                    <a href="#" title="">
                                        <div className="card">
                                            <div className="card-body">
                                                <i className="icon-lock"></i>
                                                <h3>User Control</h3>
                                                <p>Aenean sollicitudin, lorem quis bibendum auctor, nisi elit conseat ipsum, nec sagittis sem nibh.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-xl-3 col-sm-6 col-md-6 col-lg-4">
                                    <a href="#" title="">
                                        <div className="card">
                                            <div className="card-body">
                                                <i className="la la-star-o"></i>
                                                <h3 className="">5 Star Support</h3>
                                                <p className="">Aenean sollicitudin, lorem quis bibendum auctor, nisi elit conseat ipsum, nec sagittis sem nibh.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="popular-cities hp_s1 section-padding widget_edit_enabled">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-6">
                                    <div className="section-heading">
                                        <span>Popular States</span>
                                        <h3>Find Perfect Place</h3>
                                        <Link to="states">See more</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                {/* {this.state.statez.map(function (d, index) {
                                        if (d.featured)
                                            return <PerfectPlaces key={d._id} obj={d} />

                                    })} */}
                                {
                                    states ?
                                        states.map((obj, key) => <PerfectPlaces key={key} obj={obj} />)
                                        :
                                        <APILoader
                                            type="Puff"
                                            color="#00BFFF"
                                            height={50}
                                            width={50}
                                        // timeout={3000} //3 secs

                                        />
                                }


                            </div>
                        </div>
                    </section>

                    <section>
                        <div className='whatsapp-chat-container'>
                            <a className='whatsapp-btn' href='https://api.whatsapp.com/send?phone=447771850821&text=Hello%2C%20Home%20Hunt%20Support%20Assistance.%20' target="_blank" rel="noreferrer" >
                                <i className='la la-whatsapp'></i>
                                <p>
                                    Chat with us on Whatsapp
                                </p>

                            </a>
                        </div>
                    </section>

                    <a href="#" title="" className="widget_edit_enabled">
                        <section className="cta section-padding">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="cta-text">
                                            <h2>Discover a home you'll love to stay</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </a>
                </div>

                <div className="se-pre-con"></div>
            </div>
        )
    }
}

export default Home;
