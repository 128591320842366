import React from 'react';
import { Link } from 'react-router-dom';
import getImageURL from '../_helpers/imageHelper';
import { favouritesService } from '../_services/favourite.service';

class PropertyCard extends React.Component {
    state = {
        favouriteProperty: null,
        primaryImage: { path: "/public/uploads/image-home-1594136392879.jpg" }
    }
    componentDidMount() {
        let { property } = this.props;
        if (property && property.images) {
            let pImage = property.images.find(i => i.isPrimary)
            if (pImage) {
                this.setState({ primaryImage: pImage });
            }
        }
        this.fetchFavouriteProperty();
    }
    fetchFavouriteProperty = () => {
        let { property } = this.props;
        let favouriteProperty = favouritesService.get(property._id);
        this.setState({ favouriteProperty })
    }
    render() {
        let { property } = this.props;
        const { favouriteProperty, primaryImage } = this.state;
        let link = `/property/${property._id}`

        let image = property.images.find(m => m.isPrimary)
        return (
            <div className="col-lg-4 col-md-6">
                <div className="card">

                    <Link to={link} >
                        <div className="img-block ">
                            {/* <div className="overlay"></div> */}
                            {/* <span className='listing_badge badge-action'><span className='lab'>{property.badge}</span></span> */}
                            <img src={getImageURL(primaryImage.path)} style={{ width: '100%', height: 215 }} alt="Black glass house" className="img-fluid" />
                            <div className="rate-info">
                                <span className="purpose-sale">{property.purpose}</span>
                                <span className="badge-sale ml-2">{property.badge}</span>
                            </div>
                        </div>


                    </Link>
                    <div className="card-body">
                        <Link to={link} title="Black glass house">
                            <h3>{property.name}</h3>
                            <p><i className="la la-map-marker"></i>{property.address}</p>
                        </Link>
                        <br />
                        <div>
                            <ul className="container">
                                {/* <li className="">{this.props.path.path}</li> */}
                                <li className="">{"Bedrooms: " + property.bedrooms}</li>
                                <li className=""> {"Size " + property.areaSize + " m2"} </li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-footer">
                        <span className="favorites-actions pull-left">
                            <a onClick={() => {
                                if (favouriteProperty) {
                                    favouritesService.remove(property._id);
                                    this.setState({ favouriteProperty: null })
                                } else {
                                    favouritesService.save(property);
                                    this.setState({ favouriteProperty: property })
                                }

                            }} data-id="14" className={favouriteProperty ? "remove-from-favorites" : "add-to-favorites"} >
                                <i className="la la-heart-o"></i>
                            </a>
                            {/* <a href="#" data-id="14" className="remove-from-favorites" style={{ display: 'none' }}>
                                <i className="la la-heart-o"></i>
                            </a> */}
                            <i className="fa fa-spinner fa-spin fa-custom-ajax-indicator"></i>
                        </span>
                        <a href="#" title='2014-01-02 13:49:32' className="pull-right">
                            <i className="la la-calendar-check-o"></i>
                            {"Status: " + property.status}          </a>
                    </div>
                    <a href="index.php/property/14/en/black_glass_house.html" title="Black glass house" className="ext-link"></a>
                </div>
            </div>





        )
    }
}

export default PropertyCard;