import React from 'react';
import { Link } from 'react-router-dom';
import Map from '../components/Map'
import { Header } from '../components/Header';
import { apiService } from '../_services/api.service';

class Contact extends React.Component {

    render() {
        let { infosettings } = this.props;

        return (
            <div>
                <body className='modal-backdrop-effect dissable-sticky '>
                    <div >
                        <Map style={{ marginTop: '15px' }} loc={infosettings && infosettings.addressLocation.coordinates} />


                        <section className="sect-contact-featured section-padding">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xl-6">
                                    </div>
                                </div>
                                <div className="row justify-content-center row-contact">
                                    <div className="col-xl-4 col-sm-6 col-md-6 col-lg-4">
                                        <div className="contact-card">
                                            <div className="logo">
                                                <h2 className="title">
                                                    <img src="assets/img/logo.png" alt="Real estate point" className="img-fluid" />
                                                </h2>
                                                <span className="mini-title elementor-inline-editing">{infosettings && infosettings.headquaterTitle}</span>
                                            </div>
                                            <div className="address elementor-inline-editing">{infosettings && infosettings.address}</div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-sm-6 col-md-6 col-lg-4">
                                        <div className="contact-card featured">
                                            <img src="assets/images/cities/3.webp" alt="" className="cover" />
                                            <h2 className="title elementor-inline-editing">I’m agent broker</h2>
                                            <Link to="/register" className="btn-default">Register</Link>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-sm-6 col-md-6 col-lg-4">
                                        <div className="contact-card">
                                            <div className="contact_info">
                                                <h3 className="elementor-inline-editing">Other Contact Information</h3>
                                                <ul className="cont_info">
                                                    <li><i className="la la-map-marker"></i> <span className="address elementor-inline-editing">{infosettings && infosettings.address}</span></li>
                                                    <li><i className="la la-phone"></i> <a href={`tel:${infosettings && infosettings.phoneNo}`}>{infosettings && infosettings.phoneNo}</a></li>
                                                    <li><i className="la la-envelope"></i><a href={`mailto:${infosettings && infosettings.email}`}>{infosettings && infosettings.email}</a></li>
                                                </ul>
                                                <ul className="social_links">
                                                    <li><a href={infosettings && infosettings.facebookLink} target="_blank"><i className="fa fa-facebook"></i></a></li>
                                                    <li> <a href={infosettings && infosettings.twitterLink} target="_blank"><i className="fa fa-twitter"></i></a></li>
                                                    <li> <a href={infosettings && infosettings.linkedinLink} target="_blank"><i className="fa fa-linkedin"></i></a></li>
                                                    <li> <a href={infosettings && infosettings.instagramLink} target="_blank"><i className="fa fa-instagram"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>            <div className="contact-sec">
                            <div className="container">
                                <div className="contact-details-sec">
                                    <div className="row">
                                        <div className="col-lg-8 col-md-8 pl-0 pr-0">
                                            <div className="contact_form">
                                                <h3>Contact</h3>
                                                <form action="https://geniuscript.com/selio-script/index.php/en/4/contact#form-contact" method="post" className="contact-form">
                                                    <div className="form-fieldss">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-4">
                                                                <div className="form-field ">
                                                                    <input type="text" id="firstname" name="firstname" placeholder="First and last name" value="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div className="form-field ">
                                                                    <input type="email" id="email" name="email" placeholder="Email" value="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div className="form-field ">
                                                                    <input type="text" id="phone" name="phone" className="" placeholder="Phone" value="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="form-field ">
                                                                    <textarea className="" id="message" name="message" rows="10" placeholder="Message"></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12">
                                                                <div className="form-field ">
                                                                    <div className="form_captcha">
                                                                        <img src="assets/1589582781.1798.jpg" width="120" height="35" style={{ border: '0' }} alt=" " />                                                                <div className="input-control">
                                                                            <input className="captcha  " name="captcha" type="text" placeholder="Enter code from image" value="" />
                                                                            <input className="hidden" name="captcha_hash" type="text" value="1a6df" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 pl-0">
                                                                <button type="submit" className="btn-default submit">Send Message</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 pr-0">
                                            <div className="contact_info">

                                                <span style={{ fontweight: 'bold' }}>Real estate point</span><br /><span style={{ fontstyle: 'italic' }}><span style={{ fontweight: 'bold' }}><span><span>Real Estate Agency</span></span></span></span><br />Ilica 345<br />HR-10000 Zagreb<br /><br /><span style={{ fontweight: 'bold' }}><span>Tel:</span></span> +385 (0)1 123 321<br /><span style={{ fontweight: 'bold' }}><span>Fax:</span></span> +385 (0)1 123 322<br /><span style={{ fontweight: 'bold' }}><span>Mail:</span></span> info@info.info<br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </body>

            </div>
        )
    }
}

export default Contact;