import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withEvents from '../../_helpers/EventBus/withEvents';
import PageBanner from '../../components/PageBanner';

class MyProperties extends Component {
  componentDidMount(){}
  render() {
    return (
      <div>
        <PageBanner title="My Properties" />
        <div className="container">
          My Properties
        </div>
      </div>
    );
  }
}

export default MyProperties;

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return {
    loggingIn,
    alert,
  };
}

const connected = connect(mapStateToProps)(withRouter(withEvents(MyProperties)));
export { connected as MyProperties };