import React from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
export const APILoader = (props) => {
    return (
        <div style={{ width: '100%', padding: 50 }} className="text-center">
            <Loader
                {...props}
                type="Rings"
            // timeout={3000} //3 secs

            />
        </div>
    )
} 