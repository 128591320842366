import React from 'react';

class Purchase extends React.Component {

    render() {
        return (
            <div>
                <body className='modal-backdrop-effect dissable-sticky '>
                    <div >
                        <header>
                            <div className="wpart">

                                <div className="top-wrapper">
                                    <div className="container color-picker">
                                        <div className="clearfix">
                                            <a className="pick_orange" href="index8ecf.html?color=orange"> </a>
                                            <a className="pick_red" href="indexa743.html?color=red"> </a>
                                            <a className="pick_green" href="index779e.html?color=green"> </a>
                                            <a className="pick_blue" href="indexbfad.html?color=blue"> </a>
                                            <a className="pick_purple" href="index839a.html?color=purple"> </a>
                                            <a className="pick_black" href="index359d.html?color=black"> </a>
                                            <a className="pick_default" href="indexcff0.html?color=default"> </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="top-header widget_edit_enabled">
                                    <div className="container">
                                        <div className="row justify-content-between">

                                            <div className="col-xl-7 col-md-7 col-sm-12">



                                                <div className="header-address">

                                                    <a href="tel://38501123321">
                                                        <i className="la la-phone-square"></i>
                                                        <span> +385 (0)1 123 321</span>
                                                    </a>
                                                    <a href="mailto:info@my-website.com">
                                                        <i className="la la-envelope-o"></i>
                                                        <span>info@my-website.com</span>
                                                    </a>
                                                    <a href="index.php/frontend/login/en.html#content" className="login_popup_enabled"><i className="la la-user"></i> <span>Login</span></a>




                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-2 col-md-2 col-sm-6 col-6">
                                                <div className="header-social">
                                                    <a href="#">
                                                        <i className="fa fa-facebook"></i>
                                                    </a>
                                                    <a href="#">
                                                        <i className="fa fa-twitter"></i>
                                                    </a>
                                                    <a href="#">
                                                        <i className="fa fa-instagram"></i>
                                                    </a>
                                                    <a href="#">
                                                        <i className="fa fa-linkedin"></i>
                                                    </a>
                                                </div>
                                            </div>



                                            <div className="resp-grid">


                                                <div className="language-selector">
                                                    <div className="drop-menu">
                                                        <div className="select">
                                                            <span><img src="assets/img/flags/en.webp" alt="en" />
                                                                english            </span>
                                                            <i className="la la-caret-down"></i>
                                                        </div>
                                                        <ul className="dropeddown" style={{ display: 'none' }}><li className="en">
                                                            <a href="index.html" className="dropdown-item">&nbsp; <img src="assets/img/flags/en.webp" alt="en" />english</a></li><li className="hr"><a href="index.php/hr.html" className="dropdown-item">&nbsp; <img src="assets/img/flags/hr.webp" alt="hr" />croatian</a></li></ul>    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="header widget_edit_enabled">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <nav className="navbar navbar-expand-lg navbar-light">
                                                    <a href="../../../index.html" className="navbar-brand">
                                                        <img src="assets/img/logo.png" alt="Home Hunt" />
                                                    </a>

                                                    <button className="menu-button" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                                                        <span className="icon-spar"></span>
                                                        <span className="icon-spar"></span>
                                                        <span className="icon-spar"></span>
                                                    </button>
                                                    <div className="navbar-collapse" id="navbarSupportedContent">
                                                        <ul className="navbar-nav mr-auto" id="main-menu">
                                                            <li className="nav-item "><a href="index.html" className="nav-link  " >Homepage</a></li>

                                                            <li className="nav-item active"><a href="index.php/en/169/agents.html" className="nav-link  active" >Agents</a></li>
                                                            <li className="nav-item "><a href="index.php/en/147/featured.html" className="nav-link  " >Featured</a></li>
                                                            <li className="nav-item "><a href="index.php/en/4/contact.html" className="nav-link" >About us</a>
                                                            </li>
                                                            <li className="nav-item "><a href="index.php/en/4/contact.html" className="nav-link" >Contact</a></li>
                                                        </ul>
                                                        <div className="d-inline my-2 my-lg-0">
                                                            <ul className="navbar-nav">
                                                                <li className="nav-item signin-btn">

                                                                    <span className="nav-link">
                                                                        <i className="la la-sign-in"></i>
                                                                        <span>
                                                                            <a href="../../frontend/login/en.html#sw_login" className="login_popup_enabled ">
                                                                                <b className="signin-op">Sign in</b>
                                                                            </a>
                                                                            or    <a href="../../frontend/login/en.html#sw_register" className="">
                                                                                <b className="reg-op">Register</b>
                                                                            </a>
                                                                        </span>

                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <a>
                                                                        <i className="icon-plus"></i>
                                                                        <span>Submit Listing</span>
                                                                    </a>
                                                                </li>
                                                            </ul>

                                                        </div>
                                                        <a href="#" title="" className="close-menu"><i className="la la-close"></i></a>
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>                        </div>
                        </header>

                        <section className="half-map-sec">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-12">
                                        <div id="map-container" className="fullwidth-home-map">
                                            <div id="main-map" data-map-zoom="9"></div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-12">
                                        <div className="widget-property-search widget_edit_enabled">
                                            <form action="#" className="row banner-search search-form">



                                                <div className="form_field wide sf_input">
                                                    <div className="form-group  field_search_78" style="">
                                                        <input id="search_option_78" type="text" className="form-control" placeholder="Keywords" value="" />
                                                    </div>
                                                </div>


                                                <div className="form_field " style="">
                                                    <div className="form-group">

                                                        <input name="search_option_79" value="" type="text" id="search_option_79" className="form-control locationautocomplete" readonly />    </div>
                                                </div>

                                                <div className="form_field " style="">
                                                    <div className="form-group">

                                                        <input name="search_option_64" value="" type="text" id="search_option_64" className="form-control locationautocomplete" readonly />    </div>
                                                </div>

                                                <div className="form_field  sf_input">
                                                    <div className="form-group  field_search_36" style="">

                                                        <div className="drop-menu">
                                                            <div className="select">
                                                                <span>Max Sale price</span>
                                                                <i className="fa fa-angle-down"></i>
                                                            </div>
                                                            <input type="hidden" id="search_option_36_to" name="search_option_36_to" value="" />
                                                            <ul className="dropeddown">
                                                                <li>Max Sale price</li>
                                                                <li data-value="3000">3000</li>
                                                                <li data-value="5000">5000</li>
                                                                <li data-value="10000">10000</li>
                                                                <li data-value="20000">20000</li>
                                                                <li data-value="100000">100000</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="form_field  sf_input">
                                                    <div className="form-group  field_search_20" style="">
                                                        <input id="search_option_20" type="text" className="form-control" placeholder="Bedrooms" value="" />
                                                    </div>
                                                </div>

                                                <div className="feat-srch">
                                                    <div className="more-feat">
                                                        <h3> <i className="la la-cog"></i> Show More Features</h3>
                                                    </div>
                                                    <div className="form_field">
                                                        <button className="btn btn-outline-primary sw-search-start" type="submit">
                                                            <span>Search<i className="fa fa-spinner fa-spin fa-ajax-indicator hidden"></i></span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="features_list">
                                                    <div className="group">
                                                        <div className="input-field checkbox-field field_search_22 " style="">
                                                            <input type="checkbox" name="search_option_22" id="search_option_22" value="trueAir conditioning" />
                                                            <label for="search_option_22">
                                                                <span></span>
                                                                <small>Air conditioning</small>
                                                                <b className="count"></b>
                                                            </label>
                                                        </div><div className="input-field checkbox-field field_search_24 " style="">
                                                            <input type="checkbox" name="search_option_24" id="search_option_24" value="trueComputer" />
                                                            <label for="search_option_24">
                                                                <span></span>
                                                                <small>Computer</small>
                                                                <b className="count"></b>
                                                            </label>
                                                        </div><div className="input-field checkbox-field field_search_28 " style="">
                                                            <input type="checkbox" name="search_option_28" id="search_option_28" value="trueHeating" />
                                                            <label for="search_option_28">
                                                                <span></span>
                                                                <small>Heating</small>
                                                                <b className="count"></b>
                                                            </label>
                                                        </div><div className="input-field checkbox-field field_search_32 " style="">
                                                            <input type="checkbox" name="search_option_32" id="search_option_32" value="trueParking" />
                                                            <label for="search_option_32">
                                                                <span></span>
                                                                <small>Parking</small>
                                                                <b className="count"></b>
                                                            </label>
                                                        </div><div className="input-field checkbox-field field_search_23 " style="">
                                                            <input type="checkbox" name="search_option_23" id="search_option_23" value="trueCable TV" />
                                                            <label for="search_option_23">
                                                                <span></span>
                                                                <small>Cable TV</small>
                                                                <b className="count"></b>
                                                            </label>
                                                        </div><div className="input-field checkbox-field field_search_31 " style="">
                                                            <input type="checkbox" name="search_option_31" id="search_option_31" value="trueMicrowave" />
                                                            <label for="search_option_31">
                                                                <span></span>
                                                                <small>Microwave</small>
                                                                <b className="count"></b>
                                                            </label>
                                                        </div><div className="input-field checkbox-field field_search_30 " style="">
                                                            <input type="checkbox" name="search_option_30" id="search_option_30" value="trueLift" />
                                                            <label for="search_option_30">
                                                                <span></span>
                                                                <small>Lift</small>
                                                                <b className="count"></b>
                                                            </label>
                                                        </div><div className="input-field checkbox-field field_search_33 " style="">
                                                            <input type="checkbox" name="search_option_33" id="search_option_33" value="truePool" />
                                                            <label for="search_option_33">
                                                                <span></span>
                                                                <small>Pool</small>
                                                                <b className="count"></b>
                                                            </label>
                                                        </div><div className="input-field checkbox-field field_search_25 " style="">
                                                            <input type="checkbox" name="search_option_25" id="search_option_25" value="trueDishwasher" />
                                                            <label for="search_option_25">
                                                                <span></span>
                                                                <small>Dishwasher</small>
                                                                <b className="count"></b>
                                                            </label>
                                                        </div>                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="listing-directs">


                                            <div className="list-head">
                                                <div className="sortby">
                                                    <span>Sort by:</span>
                                                    <div className="drop-menu">
                                                        <div className="select">
                                                            <span>Relevant</span>
                                                            <i className="la la-caret-down"></i>
                                                        </div>
                                                        <input type="hidden" name="search_order" id="search_order" />
                                                        <ul className="dropeddown">
                                                            <li data-value="id ASC">Relevant</li>
                                                            <li data-value="id DESC">Oldest</li>
                                                            <li data-value="counter_views DESC, id DESC">Most View</li>
                                                            <li data-value="field_36_int ASC, id DESC">Higher price</li>
                                                            <li data-value="field_36_int DESC, id DESC">Lower price</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="view-count">
                                                    Results: <span className="total_rows">8</span>
                                                </div>
                                                <div className="view-change">
                                                    <ul className="nav nav-tabs grid-type">
                                                        <li className="nav-item">
                                                            <a href="#" className="nav-link grid view-type active " data-ref="grid"><i className="la la-th-large"></i></a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a href="#" className="nav-link list view-type " data-ref="list"><i className="la la-th-list"></i></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="results-container result_preload_box" id="results_conteiner">

                                                <div className="list_products">
                                                    <div className="row">

                                                        <div className="col-md-6">
                                                            <div className="card">
                                                                <a href="../property/30/en/bjelovar_estate_1.html" title="Bjelovar estate 1">
                                                                    <div className="img-block ">
                                                                        <div className="overlay"></div>
                                                                        <img src="../../files/strict_cache/851x678431262903_9d77804a5b_o%20(1).jpg" alt="Bjelovar estate 1" className="img-fluid" />
                                                                        <div className="rate-info">
                                                                            <h5>
                                                                                $90,000.00                                                                </h5>
                                                                            <span className="purpose-rent">Rent</span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div className="card-body">
                                                                    <a href="../property/30/en/bjelovar_estate_1.html" title="Bjelovar estate 1">
                                                                        <h3>Bjelovar estate 1</h3>
                                                                        <p><i className="la la-map-marker"></i>Cestica</p>
                                                                    </a>
                                                                    <ul>
                                                                        <li className="">3 Bathrooms</li>
                                                                        <li className="">3 Bedrooms</li>
                                                                        <li className="">Size 40 m2</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="card-footer">
                                                                    <span className="favorites-actions pull-left">
                                                                        <a href="#" data-id="30" className="add-to-favorites" style="">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <a href="#" data-id="30" className="remove-from-favorites" style="display:none;">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <i className="fa fa-spinner fa-spin fa-custom-ajax-indicator"></i>
                                                                    </span>
                                                                    <a href="#" title='2014-10-09 01:33:21' className="pull-right">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                        6 years            </a>
                                                                </div>
                                                                <a href="../property/30/en/bjelovar_estate_1.html" title="Bjelovar estate 1" className="ext-link"></a>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="card">
                                                                <a href="../property/14/en/black_glass_house.html" title="Black glass house">
                                                                    <div className="img-block ">
                                                                        <div className="overlay"></div>
                                                                        <span className='listing_badge badge-action'><span className='lab'>action</span></span>
                                                                        <img src="../../files/strict_cache/851x678147822442_014fc68a36_o.jpg" alt="Black glass house" className="img-fluid" />
                                                                        <div className="rate-info">
                                                                            <span className="purpose-sale">Sale</span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div className="card-body">
                                                                    <a href="../property/14/en/black_glass_house.html" title="Black glass house">
                                                                        <h3>Black glass house</h3>
                                                                        <p><i className="la la-map-marker"></i>Vatikanska 11, Zagreb, Croatia</p>
                                                                    </a>
                                                                    <ul>
                                                                        <li className="">2 Bathrooms</li>
                                                                        <li className="">2 Bedrooms</li>
                                                                        <li className="">Size 200 m2</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="card-footer">
                                                                    <span className="favorites-actions pull-left">
                                                                        <a href="#" data-id="14" className="add-to-favorites" style="">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <a href="#" data-id="14" className="remove-from-favorites" style="display:none;">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <i className="fa fa-spinner fa-spin fa-custom-ajax-indicator"></i>
                                                                    </span>
                                                                    <a href="#" title='2014-01-02 13:49:32' className="pull-right">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                        6 years            </a>
                                                                </div>
                                                                <a href="../property/14/en/black_glass_house.html" title="Black glass house" className="ext-link"></a>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="card">
                                                                <a href="../property/12/en/retro_house.html" title="Retro House">
                                                                    <div className="img-block ">
                                                                        <div className="overlay"></div>
                                                                        <span className='listing_badge badge-new'><span className='lab'>new</span></span>
                                                                        <img src="../../files/strict_cache/851x6785388057701_5a618e8a9e_b.jpg" alt="Retro House" className="img-fluid" />
                                                                        <div className="rate-info">
                                                                            <h5>
                                                                                $60000                                                                </h5>
                                                                            <span className="purpose-sale">Sale</span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div className="card-body">
                                                                    <a href="../property/12/en/retro_house.html" title="Retro House">
                                                                        <h3>Retro House</h3>
                                                                        <p><i className="la la-map-marker"></i>Zeleni put 21, Croatia</p>
                                                                    </a>
                                                                    <ul>
                                                                        <li className="">1 Bathrooms</li>
                                                                        <li className="">1 Bedrooms</li>
                                                                        <li className="">Size 60 m2</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="card-footer">
                                                                    <span className="favorites-actions pull-left">
                                                                        <a href="#" data-id="12" className="add-to-favorites" style="">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <a href="#" data-id="12" className="remove-from-favorites" style="display:none;">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <i className="fa fa-spinner fa-spin fa-custom-ajax-indicator"></i>
                                                                    </span>
                                                                    <a href="#" title='2013-12-30 22:44:07' className="pull-right">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                        6 years            </a>
                                                                </div>
                                                                <a href="../property/12/en/retro_house.html" title="Retro House" className="ext-link"></a>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="card">
                                                                <a href="../property/19/en/retro_old" title="Retro old">
                                                                    <div className="img-block ">
                                                                        <div className="overlay"></div>
                                                                        <img src="../../files/strict_cache/851x678162803672_8244db2362_o.jpg" alt="Retro old" className="img-fluid" />
                                                                        <div className="rate-info">
                                                                            <span className="purpose-sale">Sale</span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div className="card-body">
                                                                    <a href="../property/19/en/retro_old" title="Retro old">
                                                                        <h3>Retro old</h3>
                                                                        <p><i className="la la-map-marker"></i>Radoišće 23, Sveti Ivan Zelina, Croatia</p>
                                                                    </a>
                                                                    <ul>
                                                                        <li className="">- Bathrooms</li>
                                                                        <li className="">- Bedrooms</li>
                                                                        <li className="">Size 35 m2</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="card-footer">
                                                                    <span className="favorites-actions pull-left">
                                                                        <a href="#" data-id="19" className="add-to-favorites" style="">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <a href="#" data-id="19" className="remove-from-favorites" style="display:none;">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <i className="fa fa-spinner fa-spin fa-custom-ajax-indicator"></i>
                                                                    </span>
                                                                    <a href="#" title='2014-01-02 14:16:22' className="pull-right">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                        6 years            </a>
                                                                </div>
                                                                <a href="../property/19/en/retro_old" title="Retro old" className="ext-link"></a>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="card">
                                                                <a href="../property/15/en/round_towers.html" title="Round towers">
                                                                    <div className="img-block ">
                                                                        <div className="overlay"></div>
                                                                        <img src="../../files/strict_cache/851x678148583306_cad8fc948c_o.jpg" alt="Round towers" className="img-fluid" />
                                                                        <div className="rate-info">
                                                                            <span className="purpose-sale">Sale</span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div className="card-body">
                                                                    <a href="../property/15/en/round_towers.html" title="Round towers">
                                                                        <h3>Round towers</h3>
                                                                        <p><i className="la la-map-marker"></i>Cerje 1, Vrbovec, Croatia</p>
                                                                    </a>
                                                                    <ul>
                                                                        <li className="">2 Bathrooms</li>
                                                                        <li className="">2 Bedrooms</li>
                                                                        <li className="">Size 49 m2</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="card-footer">
                                                                    <span className="favorites-actions pull-left">
                                                                        <a href="#" data-id="15" className="add-to-favorites" style="">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <a href="#" data-id="15" className="remove-from-favorites" style="display:none;">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <i className="fa fa-spinner fa-spin fa-custom-ajax-indicator"></i>
                                                                    </span>
                                                                    <a href="#" title='2014-01-02 14:00:24' className="pull-right">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                        6 years            </a>
                                                                </div>
                                                                <a href="../property/15/en/round_towers.html" title="Round towers" className="ext-link"></a>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="card">
                                                                <a href="../property/13/en/yellow_wood.html" title="Yellow wood">
                                                                    <div className="img-block ">
                                                                        <div className="overlay"></div>
                                                                        <img src="../../files/strict_cache/851x6785388050419_e3ef95b8eb_b.jpg" alt="Yellow wood" className="img-fluid" />
                                                                        <div className="rate-info">
                                                                            <span className="purpose-sale">Sale</span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div className="card-body">
                                                                    <a href="../property/13/en/yellow_wood.html" title="Yellow wood">
                                                                        <h3>Yellow wood</h3>
                                                                        <p><i className="la la-map-marker"></i>Sesvete, Slatinska 23</p>
                                                                    </a>
                                                                    <ul>
                                                                        <li className="">1 Bathrooms</li>
                                                                        <li className="">1 Bedrooms</li>
                                                                        <li className="">Size 90 m2</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="card-footer">
                                                                    <span className="favorites-actions pull-left">
                                                                        <a href="#" data-id="13" className="add-to-favorites" style="">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <a href="#" data-id="13" className="remove-from-favorites" style="display:none;">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <i className="fa fa-spinner fa-spin fa-custom-ajax-indicator"></i>
                                                                    </span>
                                                                    <a href="#" title='2014-01-02 12:45:52' className="pull-right">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                        6 years            </a>
                                                                </div>
                                                                <a href="../property/13/en/yellow_wood.html" title="Yellow wood" className="ext-link"></a>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="card">
                                                                <a href="../property/11/en/sky_apartment.html" title="Sky apartment">
                                                                    <div className="img-block ">
                                                                        <div className="overlay"></div>
                                                                        <img src="../../files/strict_cache/851x6785388050721_b84cf3a0a3_b.jpg" alt="Sky apartment" className="img-fluid" />
                                                                        <div className="rate-info">
                                                                            <span className="purpose-sale">Sale</span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div className="card-body">
                                                                    <a href="../property/11/en/sky_apartment.html" title="Sky apartment">
                                                                        <h3>Sky apartment</h3>
                                                                        <p><i className="la la-map-marker"></i>Jerovec 16, Croatia</p>
                                                                    </a>
                                                                    <ul>
                                                                        <li className="">1 Bathrooms</li>
                                                                        <li className="">2 Bedrooms</li>
                                                                        <li className="">Size 70 m2</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="card-footer">
                                                                    <span className="favorites-actions pull-left">
                                                                        <a href="#" data-id="11" className="add-to-favorites" style="">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <a href="#" data-id="11" className="remove-from-favorites" style="display:none;">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <i className="fa fa-spinner fa-spin fa-custom-ajax-indicator"></i>
                                                                    </span>
                                                                    <a href="#" title='2013-12-30 22:39:03' className="pull-right">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                        6 years            </a>
                                                                </div>
                                                                <a href="../property/11/en/sky_apartment.html" title="Sky apartment" className="ext-link"></a>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="card">
                                                                <a href="../property/10/en/lux_apartment.html" title="Lux apartment">
                                                                    <div className="img-block ">
                                                                        <div className="overlay"></div>
                                                                        <span className='listing_badge badge-action'><span className='lab'>action</span></span>
                                                                        <img src="../../files/strict_cache/851x6785388047921_efc5b357c8_b.jpg" alt="Lux apartment" className="img-fluid" />
                                                                        <div className="rate-info">
                                                                            <span className="purpose-sale">Sale</span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div className="card-body">
                                                                    <a href="../property/10/en/lux_apartment.html" title="Lux apartment">
                                                                        <h3>Lux apartment</h3>
                                                                        <p><i className="la la-map-marker"></i>Cubinec 11, Croatia</p>
                                                                    </a>
                                                                    <ul>
                                                                        <li className="">1 Bathrooms</li>
                                                                        <li className="">1 Bedrooms</li>
                                                                        <li className="">Size 44 m2</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="card-footer">
                                                                    <span className="favorites-actions pull-left">
                                                                        <a href="#" data-id="10" className="add-to-favorites" style="">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <a href="#" data-id="10" className="remove-from-favorites" style="display:none;">
                                                                            <i className="la la-heart-o"></i>
                                                                        </a>
                                                                        <i className="fa fa-spinner fa-spin fa-custom-ajax-indicator"></i>
                                                                    </span>
                                                                    <a href="#" title='2013-12-30 19:43:29' className="pull-right">
                                                                        <i className="la la-calendar-check-o"></i>
                                                                        6 years            </a>
                                                                </div>
                                                                <a href="../property/10/en/lux_apartment.html" title="Lux apartment" className="ext-link"></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <nav aria-label="Page navigation example" className="pagination properties">

                                                </nav>

                                                <div className="result_preload_indic"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <script src="../../templates/selio/assets/cache/_generate_top_map_js_ce735410c2235abb40211792891abe57ae85.js?v=459"></script>
                    <div className="popup" id="sign-popup">
                        <h3>Sign In to your Account</h3>
                        <div className="popup-form form-wr">
                            <form id="popup_form_login">
                                <div className="alert alert-success m0" role="alert">
                                    <b>Demo login details for Admin:</b><br />
                                    Username: admin<br />
                                    Password:  admin<br /><br />
                                    <b> Demo login details for User:</b><br />
                                    Username:  user<br />
                                    Password:  user                </div>
                                <div className="alerts-box"></div>
                                <div className="form-field">
                                    <input type="text" name="username" value="" className="form-control" id="inputUsername" placeholder="Username" />            </div>
                                <div className="form-field">
                                    <input type="password" name="password" value="" className="form-control" id="inputPassword" placeholder="Password" />            </div>
                                <div className="form-cp">
                                    <div className="form-field">
                                        <div className="input-field">
                                            <input type="checkbox" name="remember" id="remember" value="true" />
                                            <label for="remember">
                                                <span></span>
                                                <small>Remember me</small>
                                            </label>
                                        </div>
                                    </div>
                                    <a href="../admin/user/forgetpassword.html" className="forgot-password" title="Forgot Password?">Forgot Password?</a>
                                </div>
                                <button type="submit" className="btn2">Sign In</button>
                            </form>
                            <a href="../frontend/login/en.html#sw_register" className="link-bottom">Create new account</a>
                            <a href="#" style="" className="fb-btn"><i className="fa fa-facebook" aria-hidden="true"></i>Sign in with facebook</a>
                        </div>
                    </div>

                    <div className="se-pre-con"></div>


                    <link rel="stylesheet" href="assets/unpkg.com/leaflet%401.3.1/dist/leaflet.css" /><script src="assets/unpkg.com/leaflet%401.3.1/dist/leaflet.js"></script><link rel="stylesheet" href="assets/unpkg.com/leaflet.markercluster%401.3.0/dist/MarkerCluster.css" /><link rel="stylesheet" href="assets/unpkg.com/leaflet.markercluster%401.3.0/dist/MarkerCluster.Default.css" /><script src="assets/unpkg.com/leaflet.markercluster%401.3.0/dist/leaflet.markercluster.js"></script>
                    <script src="assets/cache/big_js_footer.js"></script><script src="assets/cache/big_js_orig.js"></script>
                    <script src="assets/cache/page_js_437a51e7f4b49929f97af1c699f669d09890.js?v=325"></script>        <script src="assets/cache/_generate_contact_page_js_437a51e7f4b49929f97af1c699f669d0465b.js?v=820"></script>    </body>
            </div>
        )
    }
}

export default Purchase;