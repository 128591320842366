import React from 'react';
import { Link } from 'react-router-dom';
import Property_mainImg from '../components/Property_mainImg'
import Amenities from '../components/Amenities'
import ThumbImgs from '../components/ThumbImgs'
import StateProperty from '../components/StateProperty'
import Map from '../components/Map'
import axios from 'axios';
import { apiService } from '../_services/api.service';
import { APILoader } from '../components/APILoader';



export default class StateProperties extends React.Component {

    state = {
        properties: null,
        // data: [],
        // imgz: [],
        // category: [],
        // statez: [],
        // amenities: [],
        // coords: [],

    }


    componentDidMount() {

        //alert(myParam);
        //  alert(myParam);
        const urlParams = new URLSearchParams(window.location.search);
        // const id = urlParams.get('id');

        const id = this.props.match.params.id;
        apiService.filter("properties", { query: { state: id } }).then(properties => {
            this.setState({ properties })
        })
        // axios.get(base_ + 'api/user/properties')
        //     .then((response) => {
        //         // handle success
        //         console.log(response.data);

        //         this.setState({
        //             amenities: response.data.amenities,
        //             data: response.data,
        //             category: response.data.categoryObj,
        //             statez: response.data.stateObj,
        //             imgz: response.data.images,

        //             id_state: this.testVarible


        //         })
        //         //  console.log(this.state.id_state);

        //         //console.log(this.state.coords.coordinates);
        //     })
        //     .catch(function (error) {
        //         // handle error
        //         console.log(error);
        //     })
        //     .then(function () {
        //         // always executed
        //     });
    }



    render() {
        let { properties } = this.state;
        return (

            <div>

                <body className='modal-backdrop-effect dissable-sticky'>
                    <div >
                        <header>
                            <div className="wpart">


                                <div className="top-header widget_edit_enabled">
                                    <div className="container">
                                        <div className="row justify-content-between">

                                            <div className="col-xl-7 col-md-7 col-sm-12">



                                                <div className="header-address">

                                                    <a href="tel://38501123321">
                                                        <i className="la la-phone-square"></i>
                                                        <span> +385 (0)1 123 321</span>
                                                    </a>
                                                    <a href="mailto:info@my-website.com">
                                                        <i className="la la-envelope-o"></i>
                                                        <span>info@my-website.com</span>
                                                    </a>
                                                    <a href="index.php/frontend/login/en.html#content" className="login_popup_enabled"><i className="la la-user"></i> <span>Login</span></a>




                                                </div>
                                            </div>


                                            <div className="col-xl-3 col-lg-2 col-md-2 col-sm-6 col-6">
                                                <div className="header-social">
                                                    <a href="#">
                                                        <i className="fa fa-facebook"></i>
                                                    </a>
                                                    <a href="#">
                                                        <i className="fa fa-twitter"></i>
                                                    </a>
                                                    <a href="#">
                                                        <i className="fa fa-instagram"></i>
                                                    </a>
                                                    <a href="#">
                                                        <i className="fa fa-linkedin"></i>
                                                    </a>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                                <div className="header widget_edit_enabled">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <nav className="navbar navbar-expand-lg navbar-light">
                                                    <Link to='/' className="nav-link">
                                                        <img src="assets/img/logo.png" alt="Home Hunt" />
                                                    </Link>

                                                    <button className="menu-button" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent">
                                                        <span className="icon-spar"></span>
                                                        <span className="icon-spar"></span>
                                                        <span className="icon-spar"></span>
                                                    </button>
                                                    <div className="navbar-collapse" id="navbarSupportedContent">
                                                        <ul className="navbar-nav mr-auto" id="main-menu">
                                                            <li className="nav-item ">
                                                                <Link to='/' className="nav-link">Home</Link>

                                                            </li>

                                                            <li className="nav-item">
                                                                <Link to='/Agents' className="nav-link">Agents</Link></li>
                                                            <li className="nav-item ">
                                                                <Link to='/Featured' className="nav-link">Featured</Link></li>
                                                            <li className="nav-item ">
                                                                <Link to='/About' className="nav-link">About</Link>
                                                            </li>
                                                            <li className="nav-item "><Link to='/Contact' className="nav-link">Contact</Link></li>
                                                        </ul>
                                                        <div className="d-inline my-2 my-lg-0">
                                                            <ul className="navbar-nav">
                                                                <li className="nav-item signin-btn">

                                                                    <span className="nav-link">
                                                                        <i className="la la-sign-in"></i>
                                                                        <span>
                                                                            <Link to='/Sign_in.js' ><b className="signin-op">Sign in </b></Link>

                     or    <Link to="#sign-popup"><b className="reg-op">Register</b></Link>


                                                                        </span>

                                                                    </span>
                                                                </li>

                                                            </ul>

                                                        </div>
                                                        <a href="#" title="" className="close-menu"><i className="la la-close"></i></a>
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>      </div>
                        </header>
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                    </div>
                    <section className="half-map-sec">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-12">
                                    <Map />
                                </div>
                                <div className="col-xl-6 col-lg-12">
                                    <div className="widget-property-search widget_edit_enabled">
                                        <form action="#" className="row banner-search search-form">


                                            <div className="form_field wide sf_input">
                                                <div className="form-group  field_search_78" >
                                                    <input id="search_option_78" type="text" className="form-control" placeholder="Keywords" value="" />
                                                </div>
                                            </div>


                                            <div className="form_field " >
                                                <div className="form-group">
                                                    <input name="search_option_79" value="" type="text" id="search_option_79" className="form-control locationautocomplete" readOnly />    </div>
                                            </div>

                                            <div className="form_field ">
                                                <div className="form-group">

                                                    <input name="search_option_64" value="" type="text" id="search_option_64" className="form-control locationautocomplete" readOnly />    </div>
                                            </div>

                                            <div className="form_field  sf_input">
                                                <div className="form-group  field_search_36" >

                                                    <div className="drop-menu">
                                                        <div className="select">
                                                            <span>Max Sale price</span>
                                                            <i className="fa fa-angle-down"></i>
                                                        </div>
                                                        <input type="hidden" id="search_option_36_to" name="search_option_36_to" value="" />
                                                        <ul className="dropeddown">
                                                            <li>Max Sale price</li>
                                                            <li >3000</li>
                                                            <li>5000</li>
                                                            <li>10000</li>
                                                            <li>20000</li>
                                                            <li>100000</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="form_field  sf_input">
                                                <div className="form-group  field_search_20" >
                                                    <input id="search_option_20" type="text" className="form-control" placeholder="Bedrooms" value="" />
                                                </div>
                                            </div>
                                            <div className="feat-srch">
                                                <div className="more-feat">
                                                    <h3> <i className="la la-cog"></i> Show More Features</h3>
                                                </div>
                                                <div className="form_field">
                                                    <button className="btn btn-outline-primary sw-search-start" type="submit">
                                                        <span>Search<i className="fa fa-spinner fa-spin fa-ajax-indicator hidden"></i></span>
                                                    </button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                    <div className="row">

                                        {/* {
                                            this.state.data.map(function (d, index) {
                                                var x = "";
                                                var z = myParam;
                                                //alert(z);
                                                if (d.state == myParam) {
                                                    for (var i = 0; i < d.images.length; i++) {
                                                        if (d.images[i].isPrimary) {
                                                            x = d.images[i].path;
                                                        }
                                                    }


                                                    return <StateProperty key={d._id} path={x} obj={d} />
                                                }
                                            })} */}

                                        {
                                            properties ?
                                                properties.map((property, key) => <StateProperty property={property} key={key} />)
                                                :
                                                <APILoader
                                                    type="Puff"
                                                    color="#00BFFF"
                                                    height={50}
                                                    width={50}
                                                // timeout={3000} //3 secs

                                                />
                                        }





                                    </div>




                                </div>

                            </div>
                        </div>
                    </section>
                </body>
            </div>
        )
    }
}

