import React from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import { apiService } from '../../_services/api.service';
import validate from 'validate.js';
import io from 'socket.io-client';
import { APILoader } from '../../components/APILoader';
import Field from '../../components/field';
import PasswordField from '../../components/PasswordField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notification from '../../components/notification';
import { authenticationService } from '../../_services/authentication.service';
import { Link } from 'react-router-dom';
import { Header } from '../../components/Header';



export default class EmailVerification extends React.Component {
    state = {
        sending: false
    }
    onSendEmail = () => {
        let { sending } = this.state;
        if (!sending) {
            this.setState({ sending: true })
            let { params } = this.props.match;
            authenticationService.sendVerificationEmail(params.email)
                .then(g => {
                    console.log(g);
                    toast.success(g.message);
                    this.setState({ sending: false })
                })
                .catch(err => {
                    toast.error(err.response.data.message);
                    this.setState({ sending: false })
                })
        }
    }
    render() {
        let { params } = this.props.match;
        let { sending } = this.state;
        return (
            <div>
                <main className="main-clear">
                    <div className="selio_sw_win_wrapper">
                        <div className="ci sw_widget sw_wrap">
                            <div className="sign-form-wr">
                                <div className="sign-form-inner tab-content">
                                    <div className="form-wr sign-up-form" role="tabpanel" id="sign-up-form">
                                        <ToastContainer></ToastContainer>
                                        <h3>{params.type === "register-success" ? "Almost Done!" : "Error"}</h3>
                                        <div className="form-wr-content p-4">
                                            {params.type !== "register-success" && <h4>Your Email is not verified yet!</h4>}
                                            <span>We've emailed a verification link to:</span>
                                            <p className="text-primary">{params.email}</p>
                                            <span>Click the link in email to finish creating your account.</span>
                                            <br />
                                            <span>Didn't receive a link?</span>
                                            <br />
                                            <br />
                                            <button className="btn btn-primary btn-sm btn-block" onClick={this.onSendEmail} disabled={sending}>{sending ? "Sending.." : "Send Email"}</button>
                                            <p className="mt-1">Confirmed? <Link to="/login">Back to Login</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}