import React from 'react';
import { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

export const AuthenticatedRoute = ({ component: Component, ...rest }) => {
    useEffect(() => {
        console.log(rest);
    }, [])
    return (
        <Route {...rest} key={rest.path} render={props =>
            localStorage.getItem('token')
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        } />
    )
}