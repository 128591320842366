import { authenticationConstants } from "../_constants/authentication.constants";
import { authenticationService } from "../_services/authentication.service";
import { alertActions } from "./alert.actions";
// import { history } from "../_helpers";

export const authenticationActions = {
    login: function (email, password, history, EventBus) {
        return dispatch => {
            dispatch(alertActions.clear());
            dispatch(request({ email }));

            authenticationService.login(email, password).then(
                token => {
                    dispatch(success(token));
                    dispatch(authenticationActions.getCurrentUser(history, () => {
                        history.push("/profile");
                    }));

                },
                error => {
                    console.log(error);
                    if (error === "Your Email is not verified yet!") {
                        history.push(`/email/unverified-email/${email}`);
                    }

                    dispatch(failure(error));
                    dispatch(alertActions.error(error === "TypeError: Failed to fetch" ? "Internet Problem" : error));
                }
            );
        };
        function request(email) {
            return { type: authenticationConstants.LOGIN_REQUEST, email };
        }
        function success(token) {
            return { type: authenticationConstants.LOGIN_SUCCESS, token };
        }
        function failure(error) {
            return { type: authenticationConstants.LOGIN_FAILURE, error };
        }
    },

    getCurrentUser: function (history, callback = null) {
        return dispatch => {
            dispatch(request());
            authenticationService.getCurrentUser().then(
                authentication => {
                    localStorage.setItem('flag', btoa(JSON.stringify(authentication)));
                    dispatch(success(authentication));
                    if (callback) {
                        callback(true)
                    }
                },
                error => {
                    if (error.tokenNotFound) {
                        console.log(error);
                    } else {
                        dispatch(failure(error));
                        history.push && history.push("/login");
                    }

                    // dispatch(alertActions.error(error));
                }
            );
        };

        function request() {
            return { type: authenticationConstants.GET_CURRENT_REQUEST };
        }
        function success(authentication) {
            return { type: authenticationConstants.GET_CURRENT_SUCCESS, user: authentication.user };
        }
        function failure(error) {
            return { type: authenticationConstants.GET_CURRENT_FAILURE, error };
        }
    },
    getCurrentUserLocal: () => {
        let flag = localStorage.getItem("flag");
        if (!flag) {
            return { user: null };
        }
        return JSON.parse(atob(flag));
    },
    logout: function (history) {
        authenticationService.logout();
        history.push("/login");
        return { type: authenticationConstants.LOGOUT };
    }
};

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}