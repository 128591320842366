import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PageBanner extends Component {
    render() {
        return (
            <section className="pager-sec bfr widget_edit_enabled" style={{ marginTop: '15px' }}>
                <div className="container">
                    <div className="pager-sec-details">
                        <h3>{this.props.title}</h3>
                        <ul className="breadcrumb">

                            <li>
                                <Link to='/'>Home</Link>
                                <span className="delimiter"></span>
                            </li>
                            <li>
                                <span>{this.props.title}</span>
                            </li>
                        </ul>

                    </div>
                    <div className="float-right pr-3">
                        {this.props.children}
                    </div>
                </div>
            </section>
        );
    }
}

export default PageBanner;
