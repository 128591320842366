import React from 'react';
import { Link } from 'react-router-dom';
import { Header } from '../components/Header';
import PageBanner from '../components/PageBanner';
import { apiService } from '../_services/api.service';
import { APILoader } from '../components/APILoader';
import { Row, Col } from 'reactstrap';



class Page extends React.Component {
    state = { page: null }
    componentDidMount() {
        this.fetchPage(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.fetchPage(nextProps);
    }

    fetchPage = (props) => {
        this.setState({ page: null }, () => {
            const slug = props.match.params.slug;
            apiService.filter("pages", { "query": { "slug": slug } }).then(pages => {
                if (pages[0]) this.setState({ page: pages[0] })
            })
        })

    }
    render() {
        const { page } = this.state;
        return (
            <div>
                {
                    page ? <div>
                        <PageBanner title={page.title} />
                        <div className="animated fadeIn ">


                            <Row>
                                <Col xl={12} className="container p-5">
                                    <p dangerouslySetInnerHTML={{ __html: page.content }}></p>
                                </Col>
                            </Row>

                        </div>
                    </div> :
                        <APILoader
                            type="Puff"
                            color="#00BFFF"
                            height={50}
                            width={50}
                        // timeout={3000} //3 secs

                        />
                }

            </div>
        )
    }
}

export default Page;