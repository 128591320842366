import React, { Component } from 'react';
import { geocodeByAddress, geocodeByPlaceId, getLatLng, } from 'react-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';
import "./style.css";
const LocationSearchInput = ({ searchAddress, onChange, label }) => {
    const handleChange = address => {
        onChange({ address, latLng: searchAddress.latLng });
    };

    const handleSelect = address => {
        onChange({ address, latLng: searchAddress.latLng });
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                onChange({ address, latLng });
                console.log('Success', latLng)
            })
            .catch(error => console.error('Error', error));
    };
    return (
        <PlacesAutocomplete
            value={searchAddress.address}
            onChange={handleChange}
            onSelect={handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <input
                        {...getInputProps({
                            placeholder: `Search ${label} ...`,
                            className: 'search-input',
                        })}
                    />
                    <div className="autocomplete-dropdown-container">
                        {/* {loading && <div>Loading...</div>} */}
                        {suggestions.map((suggestion, idk) => {
                            const className = suggestion.active
                                ? 'suggestion-item--active suggestion-cont '
                                : 'suggestion-item suggestion-cont ';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                                <div
                                    key={idk}
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );

}
export default LocationSearchInput;
