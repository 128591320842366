const amountDirective = (number = 0) => {
    // return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return convertToInternationalCurrencySystem(number);
}

function convertToInternationalCurrencySystem(labelValue) {

    let roundValue = labelValue > 0 ? 1 : 0;

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(roundValue) + "B"
        // Six Zeroes for Millions 
        : Math.abs(Number(labelValue)) >= 1.0e+6

            ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(roundValue) + "M"
            // Three Zeroes for Thousands
            : Math.abs(Number(labelValue)) >= 1.0e+3

                ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(roundValue) + "K"

                : Math.abs(Number(labelValue)).toFixed(roundValue);

}

export default amountDirective;