import React from 'react';
import { Link } from 'react-router-dom';
import getImageURL from '../_helpers/imageHelper';
var base = "https://homehunt-admin.herokuapp.com";
class StateProperty extends React.Component {

    render() {
        let { property } = this.props;
        let link = `/property/${property._id}`
        let image = property.images.find(m => m.isPrimary)
        return (
            <div className="col-xl-5 col-lg-6" style={{ marginLeft: '8px' }}>
                <div className="card">

                    <Link to={link} >
                        <div className="img-block ">
                            <div className="overlay"></div>
                            <img src={getImageURL(image.path)} style={{ width: '100%', height: 215 }} alt="Black glass house" className="img-fluid" />
                            <div className="rate-info">
                                <span className="purpose-sale">{property.purpose}</span>
                            </div>     </div>
                    </Link>
                    <div className="card-body">
                        <Link to={link} title="Black glass house">
                            <h3>{property.name}</h3>
                            <p><i className="la la-map-marker"></i>{property.address}</p>
                        </Link>
                        <ul>
                            {/* <li className="">{path.path}</li> */}
                            <li className="">{"Bedrooms: " + property.bedrooms}</li>
                            <li className=""> {"Size " + property.areaSize + " m2"} </li>
                        </ul>
                    </div>
                    <div className="card-footer">
                        <span className="favorites-actions pull-left">
                            <a href="#" data-id="14" className="add-to-favorites" >
                                <i className="la la-heart-o"></i>
                            </a>
                            <a href="#" data-id="14" className="remove-from-favorites" style={{ display: 'none' }}>
                                <i className="la la-heart-o"></i>
                            </a>
                            <i className="fa fa-spinner fa-spin fa-custom-ajax-indicator"></i>
                        </span>
                        <a href="#" title='2014-01-02 13:49:32' className="pull-right">
                            <i className="la la-calendar-check-o"></i>
                            {"Status: " + property.status}          </a>
                    </div>
                    <a href="index.php/property/14/en/black_glass_house.html" title="Black glass house" className="ext-link"></a>
                </div>
            </div>





        )
    }
}

export default StateProperty;