import React from 'react';
import { Link } from 'react-router-dom';
var base = "https://homehunt-admin.herokuapp.com";
class Amenities extends React.Component {

    render() {
        return (
            <li className="pd-amenity-checkbox col-sm-6">
                <i className='la la-check-circle-o mr-1'></i>
                <small className='pd-amenity-title'>{this.props.title}</small>

            </li>
        )
    }
}

export default Amenities;